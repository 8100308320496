import {
  sizeMultiples,
  defaultLimbPositions,
  getLimbData,
  getPositionAndSize,
} from '../../asset-handling/render-helper'
import {
  getRotation,
  attributeTransition,
  attributeRotation,
  limbTransition,
  delayBase,
} from '../animation-helper'
import { renderFX } from '../fx-helper'
import * as d3 from 'd3'

// Some parameters for the size and position of body parts for the default fighter
const charSize = sizeMultiples.battle

const leftArmData = getLimbData(charSize, ...defaultLimbPositions.leftArm)
const rightArmData = getLimbData(charSize, ...defaultLimbPositions.rightArm)
const leftLegData = getLimbData(charSize, ...defaultLimbPositions.leftLeg)
const rightLegData = getLimbData(charSize, ...defaultLimbPositions.rightLeg)

function singlePunchAnimation(id) {
  const currentId = `#character${id}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  characterCard.raise() // Makes sure that the punching character is on top

  const head = characterCard.select(`#head-${characterIdNumber}`)
    , body = characterCard.select(`#body-${characterIdNumber}`)
    , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
    , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
    , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
    , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

  const headBBox = head.node().getBBox()
    , headX = headBBox.x + headBBox.width / 2
    , headY = headBBox.y + headBBox.height / 2
    , headRotation = getRotation(0, 7, headX, headY)
    , headRotation2 = getRotation(7, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
    , bodyX = bodyBBox.x + bodyBBox.width / 2
    , bodyY = bodyBBox.y + bodyBBox.height / 2
    , bodyRotation = getRotation(0, -7, bodyX, bodyY)
    , bodyRotation2 = getRotation(-7, 7, bodyX, bodyY)
    , bodyRotation3 = getRotation(7, 4, bodyX, bodyY)
    , bodyRotation4 = getRotation(4, 0, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
    , guardHandX = leftHandBBox.x + leftHandBBox.width / 2
    , guardHandY = leftHandBBox.y + leftHandBBox.height / 2
    , guardRotation = getRotation(0, -7, guardHandX, guardHandY)
    , guardRotation2 = getRotation(-7, 0, guardHandX, guardHandY)

  const rightHandBBox = rightHand.node().getBBox()
    , punchHandX = rightHandBBox.x + rightHandBBox.width / 2
    , punchHandY = rightHandBBox.y + rightHandBBox.height / 2
    , punchRotation = getRotation(0, 25, punchHandX, punchHandY)
    , punchRotation2 = getRotation(25, 19, punchHandX, punchHandY)
    , punchRotation3 = getRotation(19, -35, punchHandX, punchHandY)
    , punchRotation4 = getRotation(-35, 0, punchHandX, punchHandY)

  renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Single Punch")

  // Attribute Transition
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, 0, initialHeadPosition[0] - 0.175, initialHeadPosition[1] + 0.1)
  attributeTransition(head, delayBase, initialHeadPosition[0] + 0.165, initialHeadPosition[1] + 0.045)
  attributeTransition(head, delayBase * 2, initialHeadPosition[0] + 0.075, initialHeadPosition[1] + 0.03)
  attributeTransition(head, delayBase * 3, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, initialBodyPosition[0] - 0.1233, initialBodyPosition[1] + 0.1)
  attributeTransition(body, delayBase, initialBodyPosition[0] + 0.1, initialBodyPosition[1] + 0.04)
  attributeTransition(body, delayBase * 2, initialBodyPosition[0] + 0.02, initialBodyPosition[1])
  attributeTransition(body, delayBase * 3, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, initialLeftHandPosition[0] - 0.3333, initialLeftHandPosition[1] + 0.1133)
  attributeTransition(leftHand, delayBase, initialLeftHandPosition[0] + 0.055, initialLeftHandPosition[1] - 0.06)
  attributeTransition(leftHand, delayBase * 2, initialLeftHandPosition[0] - 0.01, initialLeftHandPosition[1] - 0.08)
  attributeTransition(leftHand, delayBase * 3, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, initialRightHandPosition[0] - 0.23, 0.71, 0.24)
  attributeTransition(rightHand, delayBase, initialRightHandPosition[0] + 0.5, initialRightHandPosition[1] - 0.02, 0.29)
  attributeTransition(rightHand, delayBase * 2, initialRightHandPosition[0] + 0.32, initialRightHandPosition[1] - 0.04)
  attributeTransition(rightHand, delayBase * 3, initialRightHandPosition[0], initialRightHandPosition[1])

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, 0, initialLeftFootPosition[0] - 0.05, 1.1667, 0.33)
  attributeTransition(leftFoot, delayBase, initialLeftFootPosition[0], initialLeftFootPosition[1])

  // Attribute Rotation
  attributeRotation(head, delayBase, headRotation)
  attributeRotation(head, delayBase * 2, headRotation2)

  attributeRotation(body, 0, bodyRotation)
  attributeRotation(body, delayBase, bodyRotation2)
  attributeRotation(body, delayBase * 2, bodyRotation3)
  attributeRotation(body, delayBase * 3, bodyRotation4)

  attributeRotation(leftHand, delayBase * 2, guardRotation)
  attributeRotation(leftHand, delayBase * 3, guardRotation2)

  attributeRotation(rightHand, 0, punchRotation3)
  attributeRotation(rightHand, delayBase, punchRotation2)
  attributeRotation(rightHand, delayBase * 2, punchRotation)
  attributeRotation(rightHand, delayBase * 3, punchRotation4)

  const newLeftArmData = getLimbData(charSize, 0.25, 0.5, 0.15, 0.85, -0.01, 0.6)
  const newRightArmData = getLimbData(charSize, 0.42, 0.58, 0.71, 0.8, 0.64, 0.6)
  const newLeftLegData = getLimbData(charSize, 0.25, 0.795, -0.01, 0.78, 0.24, 0.92)
  const newRightLegData = getLimbData(charSize, 0.45, 0.77, 0.55, 0.87, 0.76, 0.9)

  const newLeftArmData2 = getLimbData(charSize, 0.35, 0.51, 0.05, 0.6, 0.355, 0.55)
  const newRightArmData2 = getLimbData(charSize, 0.66, 0.55, 0.77, 0.555, 1.27, 0.56)
  const newLeftLegData2 = getLimbData(charSize, 0.49, 0.72, 0.33, 0.84, 0.105, 0.918)
  const newRightLegData2 = getLimbData(charSize, 0.55, 0.71, 0.85, 0.82, 0.6, 0.91)

  const newLeftArmData3 = getLimbData(charSize, 0.32, 0.49, -0.015, 0.64, 0.275, 0.555)
  const newRightArmData3 = getLimbData(charSize, 0.54, 0.52, 0.77, 0.525, 1.1, 0.53)
  const newLeftLegData3 = getLimbData(charSize, 0.39, 0.72, 0.33, 0.84, 0.105, 0.918)
  const newRightLegData3 = getLimbData(charSize, 0.55, 0.71, 0.72, 0.82, 0.6, 0.91)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, delayBase, newLeftArmData2)
  limbTransition(leftArm, delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, delayBase * 3, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, delayBase, newRightArmData2)
  limbTransition(rightArm, delayBase * 2, newRightArmData3)
  limbTransition(rightArm, delayBase * 3, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayBase * 2, newLeftLegData3)
  limbTransition(leftLeg, delayBase * 3, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, 0, newRightLegData)
  limbTransition(rightLeg, delayBase, newRightLegData2)
  limbTransition(rightLeg, delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, delayBase * 3, rightLegData)
}

export {
  singlePunchAnimation
}