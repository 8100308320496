import {
  sizeMultiples,
  defaultLimbPositions,
  getLimbData,
  getPositionAndSize,
} from '../../asset-handling/render-helper'
import {
  getRotation,
  attributeTransition,
  attributeRotation,
  limbTransition,
  delayBase,
} from '../animation-helper'
import * as d3 from 'd3'

// Some parameters for the size and position of body parts for the default fighter
const charSize = sizeMultiples.battle

const leftArmData = getLimbData(charSize, ...defaultLimbPositions.leftArm)
const rightArmData = getLimbData(charSize, ...defaultLimbPositions.rightArm)
const leftLegData = getLimbData(charSize, ...defaultLimbPositions.leftLeg)
const rightLegData = getLimbData(charSize, ...defaultLimbPositions.rightLeg)

// Start the shield
function startDefendAnimation(id, allTimeouts) {
  const currentId = `#character${id}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  //adjustPositionDefend(currentId, opponentId, direction)

  const head = characterCard.select(`#head-${characterIdNumber}`)
    , body = characterCard.select(`#body-${characterIdNumber}`)
    , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
    , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`);

  const headBBox = head.node().getBBox()
    , headX = headBBox.x + headBBox.width / 2
    , headY = headBBox.y + headBBox.height / 2
    , headRotation = getRotation(0, 4, headX, headY)
    , headRotation2 = getRotation(4, -6, headX, headY)

  const bodyBBox = body.node().getBBox()
    , bodyX = bodyBBox.x + bodyBBox.width / 2
    , bodyY = bodyBBox.y + bodyBBox.height / 2
    , bodyRotation = getRotation(0, 8, bodyX, bodyY)
    , bodyRotation2 = getRotation(8, -9, bodyX, bodyY)
    , bodyRotation3 = getRotation(-9, -14, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
    , leftHandX = leftHandBBox.x + leftHandBBox.width / 2
    , leftHandY = leftHandBBox.y + leftHandBBox.height / 2
    , leftHandRotation = getRotation(0, -12, leftHandX, leftHandY)
    , leftHandRotation2 = getRotation(-12, -38, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
    , rightHandX = rightHandBBox.x + rightHandBBox.width / 2
    , rightHandY = rightHandBBox.y + rightHandBBox.height / 2
    , rightHandRotation = getRotation(0, 18, rightHandX, rightHandY)
    , rightHandRotation2 = getRotation(18, -28, rightHandX, rightHandY)

  const startShield1 = characterCard.select(`#start-shield-1-${characterIdNumber}`)
  const startShield2 = characterCard.select(`#start-shield-2-${characterIdNumber}`)
  const startShield3 = characterCard.select(`#start-shield-3-${characterIdNumber}`)
  const startShield4 = characterCard.select(`#start-shield-4-${characterIdNumber}`)

  startShield1.attr("display", "block")
  allTimeouts.push(setTimeout(() => {
    startShield1.attr("display", "none")
    startShield2.attr("display", "block")
  }, delayBase))
  allTimeouts.push(setTimeout(() => {
    startShield2.attr("display", "none")
    startShield3.attr("display", "block")
  }, delayBase * 2))
  allTimeouts.push(setTimeout(() => {
    startShield3.attr("display", "none")
    startShield4.attr("display", "block")
  }, delayBase * 3))

  // Attribute Translation
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, 0, initialHeadPosition[0] + 0.06, initialHeadPosition[1] + 0.05)
  attributeTransition(head, delayBase, initialHeadPosition[0] - 0.18, initialHeadPosition[1] + 0.07)
  attributeTransition(head, delayBase * 2, initialHeadPosition[0] - 0.23, initialHeadPosition[1] + 0.1)
  attributeTransition(head, delayBase * 3, initialHeadPosition[0] - 0.21, initialHeadPosition[1] + 0.09)

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, initialBodyPosition[0] + 0.01, initialBodyPosition[1] + 0.01)
  attributeTransition(body, delayBase, initialBodyPosition[0] - 0.05, initialBodyPosition[1] + 0.06)
  attributeTransition(body, delayBase * 2, initialBodyPosition[0] - 0.1, initialBodyPosition[1] + 0.08)
  attributeTransition(body, delayBase * 3, initialBodyPosition[0] - 0.08, initialBodyPosition[1] + 0.07)

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, initialLeftHandPosition[0] + 0.07, initialLeftHandPosition[1] - 0.08)
  attributeTransition(leftHand, delayBase, initialLeftHandPosition[0] + 0.055, initialLeftHandPosition[1] - 0.1)
  attributeTransition(leftHand, delayBase * 2, initialLeftHandPosition[0], initialLeftHandPosition[1] - 0.06)
  attributeTransition(leftHand, delayBase * 3, initialLeftHandPosition[0] + 0.02, initialLeftHandPosition[1] - 0.07)

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, initialRightHandPosition[0] - 0.17, initialRightHandPosition[1] + 0.1)
  attributeTransition(rightHand, delayBase, initialRightHandPosition[0] - 0.26, initialRightHandPosition[1] + 0.095)
  attributeTransition(rightHand, delayBase * 2, initialRightHandPosition[0] - 0.32, initialRightHandPosition[1] + 0.11)
  attributeTransition(rightHand, delayBase * 3, initialRightHandPosition[0] - 0.3, initialRightHandPosition[1] + 0.1)

  // Attribute Rotation
  attributeRotation(head, 0, headRotation)
  attributeRotation(head, delayBase, headRotation2)

  attributeRotation(body, 0, bodyRotation)
  attributeRotation(body, delayBase, bodyRotation2)
  attributeRotation(body, delayBase * 2, bodyRotation3)

  attributeRotation(leftHand, 0, leftHandRotation)
  attributeRotation(leftHand, delayBase, leftHandRotation2)

  attributeRotation(rightHand, 0, rightHandRotation)
  attributeRotation(rightHand, delayBase, rightHandRotation2)

  const newLeftArmData = getLimbData(charSize, 0.39, 0.45, 0.12, 0.67, 0.39, 0.51)
  const newRightArmData = getLimbData(charSize, 0.54, 0.6, 0.57, 0.66, 0.67, 0.63)
  const newLeftLegData = getLimbData(charSize, 0.36, 0.7, 0.37, 0.87, 0.115, 0.91)
  const newRightLegData = getLimbData(charSize, 0.5, 0.71, 0.69, 0.8, 0.59, 0.92)

  const newLeftArmData2 = getLimbData(charSize, 0.25, 0.49, 0.26, 0.72, 0.41, 0.535)
  const newRightArmData2 = getLimbData(charSize, 0.46, 0.55, 0.84, 0.53, 0.64, 0.62)
  const newLeftLegData2 = getLimbData(charSize, 0.28, 0.73, 0.44, 0.9, 0.115, 0.91)
  const newRightLegData2 = getLimbData(charSize, 0.5, 0.75, 0.69, 0.82, 0.59, 0.92)

  const newLeftArmData3 = getLimbData(charSize, 0.22, 0.49, 0.21, 0.77, 0.38, 0.535)
  const newRightArmData3 = getLimbData(charSize, 0.44, 0.55, 0.77, 0.54, 0.6, 0.62)
  const newLeftLegData3 = getLimbData(charSize, 0.28, 0.78, 0.48, 0.9, 0.115, 0.91)
  const newRightLegData3 = getLimbData(charSize, 0.48, 0.75, 0.71, 0.82, 0.59, 0.92)

  const newLeftArmData4 = getLimbData(charSize, 0.22, 0.49, 0.21, 0.75, 0.38, 0.535)
  const newRightArmData4 = getLimbData(charSize, 0.44, 0.55, 0.78, 0.54, 0.6, 0.62)
  const newLeftLegData4 = getLimbData(charSize, 0.31, 0.78, 0.48, 0.9, 0.115, 0.91)
  const newRightLegData4 = getLimbData(charSize, 0.48, 0.75, 0.73, 0.82, 0.6, 0.92)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, delayBase, newLeftArmData2)
  limbTransition(leftArm, delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, delayBase * 3, newLeftArmData4)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, delayBase, newRightArmData2)
  limbTransition(rightArm, delayBase * 2, newRightArmData3)
  limbTransition(rightArm, delayBase * 3, newRightArmData4)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayBase * 2, newLeftLegData3)
  limbTransition(leftLeg, delayBase * 3, newLeftLegData4)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, 0, newRightLegData)
  limbTransition(rightLeg, delayBase, newRightLegData2)
  limbTransition(rightLeg, delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, delayBase * 3, newRightLegData4)
}

// End the shield after they are done holding it or it gets broken
function endDefendAnimation(id, allTimeouts) {
  const currentId = `#character${id}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  const head = characterCard.select(`#head-${characterIdNumber}`)
    , body = characterCard.select(`#body-${characterIdNumber}`)
    , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
    , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`);

  const headBBox = head.node().getBBox()
    , headX = headBBox.x + headBBox.width / 2
    , headY = headBBox.y + headBBox.height / 2
    , headRotation = getRotation(-6, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
    , bodyX = bodyBBox.x + bodyBBox.width / 2
    , bodyY = bodyBBox.y + bodyBBox.height / 2
    , bodyRotation = getRotation(-14, 0, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
    , leftHandX = leftHandBBox.x + leftHandBBox.width / 2
    , leftHandY = leftHandBBox.y + leftHandBBox.height / 2
    , leftHandRotation = getRotation(-38, 0, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
    , rightHandX = rightHandBBox.x + rightHandBBox.width / 2
    , rightHandY = rightHandBBox.y + rightHandBBox.height / 2
    , rightHandRotation = getRotation(-28, 0, rightHandX, rightHandY)

  const initialHeadPosition = getPositionAndSize("head")
  const initialBodyPosition = getPositionAndSize("body")
  const initialLeftHandPosition = getPositionAndSize("leftHand")
  const initialRightHandPosition = getPositionAndSize("rightHand")

  attributeTransition(head, 0, initialHeadPosition[0] - 0.17, initialHeadPosition[1] + 0.07)
  attributeTransition(body, 0, initialBodyPosition[0] - 0.06, initialBodyPosition[1] + 0.07)
  attributeTransition(leftHand, 0, initialLeftHandPosition[0] + 0.065, initialLeftHandPosition[1] - 0.09)
  attributeTransition(rightHand, 0, initialRightHandPosition[0] - 0.27, initialRightHandPosition[1] + 0.1)

  const newLeftArmData = getLimbData(charSize, 0.27, 0.49, 0.24, 0.71, 0.43, 0.535)
  const newRightArmData = getLimbData(charSize, 0.44, 0.55, 0.85, 0.54, 0.6, 0.62)
  const newLeftLegData = getLimbData(charSize, 0.32, 0.78, 0.46, 0.9, 0.115, 0.91)
  const newRightLegData = getLimbData(charSize, 0.48, 0.75, 0.73, 0.82, 0.6, 0.92)

  const leftArm = characterCard.select("#leftArm")
  const rightArm = characterCard.select("#rightArm")
  const leftLeg = characterCard.select("#leftLeg")
  const rightLeg = characterCard.select("#rightLeg")

  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(rightLeg, 0, newRightLegData)

  allTimeouts.push(setTimeout(() => {
    characterCard.select(`#start-shield-4-${characterIdNumber}`).attr("display", "none")
    characterCard.select(`#end-shield-${characterIdNumber}`).attr("display", "block")
  }, 0))
  allTimeouts.push(setTimeout(() => {
    characterCard.selectAll(".shield-icon").attr("display", "none")
  }, delayBase))

  attributeRotation(head, 0 + delayBase, headRotation)
  attributeRotation(body, 0 + delayBase, bodyRotation)
  attributeRotation(leftHand, 0 + delayBase, leftHandRotation)
  attributeRotation(rightHand, 0 + delayBase, rightHandRotation)

  attributeTransition(head, 0 + delayBase, initialHeadPosition[0], initialHeadPosition[1])
  attributeTransition(body, 0 + delayBase, initialBodyPosition[0], initialBodyPosition[1])
  attributeTransition(leftHand, 0 + delayBase, initialLeftHandPosition[0], initialLeftHandPosition[1])
  attributeTransition(rightHand, 0 + delayBase, initialRightHandPosition[0], initialRightHandPosition[1])

  limbTransition(leftArm, 0 + delayBase, leftArmData)
  limbTransition(rightArm, 0 + delayBase, rightArmData)
  limbTransition(leftLeg, 0 + delayBase, leftLegData)
  limbTransition(rightLeg, 0 + delayBase, rightLegData)

}

export {
  startDefendAnimation,
  endDefendAnimation
}