const possibleTypes = ["Fire", "Water", "Electric"]
const maxFutureComposableBots = 10
const maxCurrentComposableBots = 1

const oldToNewKeyMapping = {
  "0": "onSale",
  "1": "price",
  "2": "owner",
  "3": "attributes",
  "4": "neuralNetId",
  "5": "generation"
}

const replaceKeys = (keyMapping, obj) => {
  var newObj = {}
  for (const [key, value] of Object.entries(obj)) {
    newObj[keyMapping[key]] = value
  }
  return newObj
}

const getTruncatedDna = (rawDna) => {
  var truncatedDna
  const dnaLength = rawDna.length
  if (dnaLength > 16) {
    truncatedDna = rawDna.slice(dnaLength-16, dnaLength)
  }
  else {
    truncatedDna = rawDna
  }
  var dnaReplacement
  if (rawDna < maxFutureComposableBots) {
    dnaReplacement = `composable-${Math.min(rawDna, maxCurrentComposableBots)}--off`
  }
  return [truncatedDna, dnaReplacement]
}

async function getFighterValuesFromBlockchain(smartContract, rankedBattleContract, tokenId) {
  const rawFighterInfo = await smartContract.methods.getAllFighterInfo(tokenId).call()
  const battleRecord = await rankedBattleContract.methods.getBattleRecord(tokenId).call()
  const fighterInfo = replaceKeys(oldToNewKeyMapping, rawFighterInfo)
  const attributes =[...fighterInfo.attributes]

  const rawDna = attributes[4]
  const [truncatedDna, dnaReplacement] = getTruncatedDna(rawDna)
  
  const currentFighter = {
    ...fighterInfo,
    battleRecord: [battleRecord['0'], battleRecord['1'], battleRecord['2']],
    type: possibleTypes[truncatedDna % 3],
    power: attributes[0],
    speed: attributes[1],
    defence: attributes[2],
    accuracy: attributes[3],
    dna: dnaReplacement !== undefined ? dnaReplacement : truncatedDna
  }
  delete currentFighter.attributes
  return currentFighter
}

const createFighterObject = (owner, fighterValues) => {
  const binary = parseInt(fighterValues.dna).toString(2)

  const newCharacter = {
    owner: owner,
    id: fighterValues.id,
    dna: fighterValues.dna,
    binary: binary,
    battleAttributes: {
      power: fighterValues.power,
      speed: fighterValues.speed,
      defence: fighterValues.defence,
      accuracy: fighterValues.accuracy
    },
    battleRecord: fighterValues.battleRecord,
    neuralNetId: fighterValues.neuralNetId,
    type: fighterValues.type !== undefined ? fighterValues.type : possibleTypes[fighterValues.dna % 3],
    price: fighterValues.price,
    cardFlipped: fighterValues.cardFlipped,
    generation: fighterValues.generation,
    rarity: fighterValues.rarity,
    performance: fighterValues.performance,
    salePostedTime: fighterValues.salePostedTime,
    renderCrown: fighterValues.renderCrown,
    availability: fighterValues.availability,
    composableMetadata: fighterValues.composableMetadata
  }
  return newCharacter
}

export {
  possibleTypes,
  getTruncatedDna,
  getFighterValuesFromBlockchain,
  createFighterObject
}
