import {
  sizeMultiples,
  defaultLimbPositions,
  getLimbData,
  getPositionAndSize,
} from '../../asset-handling/render-helper'
import {
  getRotation,
  attributeTransition,
  attributeRotation,
  limbTransition,
  delayBase,
} from '../animation-helper'
import { renderFX } from '../fx-helper'
import { singlePunchAnimation } from './single-punch'
import * as d3 from 'd3'

// Some parameters for the size and position of body parts for the default fighter
const charSize = sizeMultiples.battle

const leftArmData = getLimbData(charSize, ...defaultLimbPositions.leftArm)
const rightArmData = getLimbData(charSize, ...defaultLimbPositions.rightArm)
const leftLegData = getLimbData(charSize, ...defaultLimbPositions.leftLeg)
const rightLegData = getLimbData(charSize, ...defaultLimbPositions.rightLeg)


function doublePunchAnimation(id, allTimeouts) {

  const currentId = `#character${id}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  singlePunchAnimation(id)
  const delayStart = delayBase * 4

  const head = characterCard.select(`#head-${characterIdNumber}`)
    , body = characterCard.select(`#body-${characterIdNumber}`)
    , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
    , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
    , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
    , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

  const headBBox = head.node().getBBox()
    , headX = headBBox.x + headBBox.width / 2
    , headY = headBBox.y + headBBox.height / 2
    , headRotation = getRotation(0, -5, headX, headY)
    , headRotation2 = getRotation(-5, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
    , bodyX = bodyBBox.x + bodyBBox.width / 2
    , bodyY = bodyBBox.y + bodyBBox.height / 2
    , bodyRotation = getRotation(0, -7, bodyX, bodyY)
    , bodyRotation2 = getRotation(-7, 16, bodyX, bodyY)
    , bodyRotation3 = getRotation(16, 7, bodyX, bodyY)
    , bodyRotation4 = getRotation(7, 0, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
    , leftHandX = leftHandBBox.x + leftHandBBox.width / 2
    , leftHandY = leftHandBBox.y + leftHandBBox.height / 2
    , leftHandRotation = getRotation(0, 10, leftHandX, leftHandY)
    , leftHandRotation2 = getRotation(10, 0, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
    , rightHandX = rightHandBBox.x + rightHandBBox.width / 2
    , rightHandY = rightHandBBox.y + rightHandBBox.height / 2
    , rightHandRotation = getRotation(19, -35, rightHandX, rightHandY)
    , rightHandRotation2 = getRotation(-35, 0, rightHandX, rightHandY)

  allTimeouts.push(setTimeout(() => {
    renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Double Punch")
  }, delayStart))

  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, delayStart, initialHeadPosition[0] - 0.21, initialHeadPosition[1] + 0.1)
  attributeTransition(head, delayStart + delayBase, initialHeadPosition[0] + 0.07, initialHeadPosition[1] + 0.06)
  attributeTransition(head, delayStart + delayBase * 2, initialHeadPosition[0] + 0.01, initialHeadPosition[1] + 0.06)
  attributeTransition(head, delayStart + delayBase * 3, initialHeadPosition[0], initialHeadPosition[1] + 0.05)
  attributeTransition(head, delayStart + delayBase * 4, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, delayStart, initialBodyPosition[0] - 0.13, initialBodyPosition[1] + 0.1)
  attributeTransition(body, delayStart + delayBase, initialBodyPosition[0] + 0.06, initialBodyPosition[1] + 0.01)
  attributeTransition(body, delayStart + delayBase * 2, initialBodyPosition[0] + 0.06, initialBodyPosition[1] + 0.03)
  attributeTransition(body, delayStart + delayBase * 3, initialBodyPosition[0] + 0.03, 0.72)
  attributeTransition(body, delayStart + delayBase * 4, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, delayStart, initialLeftHandPosition[0] - 0.39, initialLeftHandPosition[1] + 0.13)
  attributeTransition(leftHand, delayStart + delayBase, initialLeftHandPosition[0] + 0.96, initialLeftHandPosition[1] - 0.03)
  attributeTransition(leftHand, delayStart + delayBase * 2, initialLeftHandPosition[0] + 0.79, initialLeftHandPosition[1] - 0.015)
  attributeTransition(leftHand, delayStart + delayBase * 3, initialLeftHandPosition[0] + 0.4, initialLeftHandPosition[1])
  attributeTransition(leftHand, delayStart + delayBase * 4, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, delayStart, initialRightHandPosition[0] - 0.24, initialRightHandPosition[1] + 0.03)
  attributeTransition(rightHand, delayStart + delayBase, initialRightHandPosition[0] - 0.39, initialRightHandPosition[1] + 0.03)
  attributeTransition(rightHand, delayStart + delayBase * 2, initialRightHandPosition[0] - 0.39, initialRightHandPosition[1] + 0.075)
  attributeTransition(rightHand, delayStart + delayBase * 3, initialRightHandPosition[0] - 0.3, initialRightHandPosition[1])
  attributeTransition(rightHand, delayStart + delayBase * 4, initialRightHandPosition[0], initialRightHandPosition[1])

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, delayStart, initialLeftFootPosition[0] - 0.06, initialLeftFootPosition[1])
  attributeTransition(leftFoot, delayStart + delayBase, initialLeftFootPosition[0], initialLeftFootPosition[1])
  attributeTransition(leftFoot, delayStart + delayBase * 2, 0.025, 1.1667, 0.33)
  attributeTransition(leftFoot, delayStart + delayBase * 4, initialLeftFootPosition[0], initialLeftFootPosition[1])

  // Attribute Rotation
  attributeRotation(head, delayStart, headRotation)
  attributeRotation(head, delayStart + delayBase, headRotation2)

  attributeRotation(body, delayStart, bodyRotation)
  attributeRotation(body, delayStart + delayBase, bodyRotation2)
  attributeRotation(body, delayStart + delayBase * 2, bodyRotation3)
  attributeRotation(body, delayStart + delayBase * 3, bodyRotation4)
  // rightHand.raise()
  attributeRotation(rightHand, delayStart, rightHandRotation)
  attributeRotation(rightHand, delayStart + delayBase * 3, rightHandRotation2)

  attributeRotation(leftHand, delayStart + delayBase, leftHandRotation)
  attributeRotation(leftHand, delayStart + delayBase * 4, leftHandRotation2)

  const newLeftArmData = getLimbData(charSize, 0.24, 0.5, 0.09, 0.86, -0.06, 0.6)
  const newRightArmData = getLimbData(charSize, 0.42, 0.58, 0.74, 0.79, 0.64, 0.6)
  const newLeftLegData = getLimbData(charSize, 0.25, 0.795, -0.01, 0.78, 0.22, 0.92)
  const newRightLegData = getLimbData(charSize, 0.4, 0.76, 0.6, 0.89, 0.77, 0.895)

  const newLeftArmData2 = getLimbData(charSize, 0.45, 0.51, 0.6, 0.56, 1.24, 0.54)
  const newRightArmData2 = getLimbData(charSize, 0.42, 0.5, 0.23, 0.5, 0.44, 0.55)
  const newLeftLegData2 = getLimbData(charSize, 0.42, 0.69, 0.33, 0.78, 0.11, 0.92)
  const newRightLegData2 = getLimbData(charSize, 0.55, 0.72, 0.75, 0.8, 0.59, 0.92)

  const newLeftArmData3 = getLimbData(charSize, 0.45, 0.51, 0.6, 0.56, 1.24, 0.54)
  const newRightArmData3 = getLimbData(charSize, 0.42, 0.525, 0.21, 0.54, 0.44, 0.58)
  const newLeftLegData3 = getLimbData(charSize, 0.42, 0.69, 0.36, 0.78, 0.095, 0.92)
  const newRightLegData3 = getLimbData(charSize, 0.55, 0.73, 0.78, 0.8, 0.59, 0.92)

  const newLeftArmData4 = getLimbData(charSize, 0.4, 0.52, 0.14, 0.58, 0.7, 0.56)
  const newRightArmData4 = getLimbData(charSize, 0.55, 0.54, 0.555, 0.555, 0.56, 0.54)
  const newLeftLegData4 = getLimbData(charSize, 0.4, 0.66, 0.36, 0.78, 0.095, 0.92)
  const newRightLegData4 = getLimbData(charSize, 0.55, 0.72, 0.72, 0.81, 0.595, 0.92)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, delayStart, newLeftArmData)
  limbTransition(leftArm, delayStart + delayBase, newLeftArmData2)
  limbTransition(leftArm, delayStart + delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, delayStart + delayBase * 3, newLeftArmData4)
  limbTransition(leftArm, delayStart + delayBase * 4, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, delayStart, newRightArmData)
  limbTransition(rightArm, delayStart + delayBase, newRightArmData2)
  limbTransition(rightArm, delayStart + delayBase * 2, newRightArmData3)
  limbTransition(rightArm, delayStart + delayBase * 3, newRightArmData4)
  limbTransition(rightArm, delayStart + delayBase * 4, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, delayStart, newLeftLegData)
  limbTransition(leftLeg, delayStart + delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayStart + delayBase * 2, newLeftLegData3)
  limbTransition(leftLeg, delayStart + delayBase * 3, newLeftLegData4)
  limbTransition(leftLeg, delayStart + delayBase * 4, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, delayStart, newRightLegData)
  limbTransition(rightLeg, delayStart + delayBase, newRightLegData2)
  limbTransition(rightLeg, delayStart + delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, delayStart + delayBase * 3, newRightLegData4)
  limbTransition(rightLeg, delayStart + delayBase * 4, rightLegData)
}

export {
  doublePunchAnimation
}