import React from 'react'
import Statistics from '../aggregate-stats-componenets/Statistics'
import '../../../css/website-scaffolding/home.css'
import '../../../index.css'

const Home = () => {
  return (
    <>
      <div id="home__container">
        <Statistics />
      </div>
    </>
  )
}

export default Home
