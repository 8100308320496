import React, { useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Context } from '../../../Store'
import Leaderboard from './Leaderboard'
import Loader from '../../generic-components/Loader'
import * as d3 from 'd3'

const LeaderboardContainer = () => {
  const [ethereumInfo, setEthereumInfo] = useContext(Context)
  const showLoader = ethereumInfo.web3 === undefined
  const location = useLocation()

  var leaderboardType = "Country"
  if (location.state !== undefined) {
     leaderboardType = location.state
  }

  useEffect(() => {
    const tabPrefix = leaderboardType.toLowerCase()
    d3.select("body").classed("techy-hexagon-background", true)
    d3.select("header").selectAll("li").classed("chosen-tab", false)
    d3.select("header").select(`#${tabPrefix}-leaderboard-tab`).classed("chosen-tab", true)
    return () => {
      d3.select("body").classed("techy-hexagon-background", false)
    }
  }, [location.state])

  return (
    <>
      {showLoader && <Loader repeatBool={true} smallLoaderBool={true}></Loader>}
      {!showLoader && <Leaderboard leaderboardType={leaderboardType}></Leaderboard>}
    </>
  )
}

export default LeaderboardContainer
