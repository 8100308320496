const truncateName = (name, nCharacters) => {
  if (nCharacters === undefined) nCharacters = 10
  const stringLength = name.length
  if (stringLength > nCharacters) {
    return name.slice(0, nCharacters) + "..."
  }
  return name
}

export {
  truncateName
}