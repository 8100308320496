import cryptoPunksLogo from '../../../local-assets/other-nft-projects/cryptopunks-logo.png'
import baycLogo from '../../../local-assets/other-nft-projects/bayc-logo.png'
import maycLogo from '../../../local-assets/other-nft-projects/mayc-logo.png'
import bakcLogo from '../../../local-assets/other-nft-projects/bakc-logo.png'
import pudgyPenguinsLogo from '../../../local-assets/other-nft-projects/pudgy-penguins-logo.jpeg'
import coolCatsLogo from '../../../local-assets/other-nft-projects/cool-cats-logo.png'
import metaHeroLogo from '../../../local-assets/other-nft-projects/metahero-logo.jpeg'
import rumbleKongLogo from '../../../local-assets/other-nft-projects/rumble-kong-logo.png'
import lazyLionsLogo from '../../../local-assets/other-nft-projects/lazy-lions-logo.png'
import theAlienBoyLogo from '../../../local-assets/other-nft-projects/the-alien-boy-logo.gif'
import dizzyDragonsLogo from '../../../local-assets/other-nft-projects/dizzy-dragons-logo.gif'
import cyberKongzLogo from '../../../local-assets/other-nft-projects/cyberkongz-logo.gif'
import axios from 'axios'
require('dotenv').config()

const backend = process.env.REACT_APP_BACKEND

const projectContracts = {
  "CryptoPunks": "0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB",
  "BAYC": "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D",
  "MAYC": "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
  "BAKC": "0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623",
  "Pudgy Penguins": "0xBd3531dA5CF5857e7CfAA92426877b022e612cf8",
  "Cool Cats": "0x1A92f7381B9F03921564a437210bB9396471050C",
  "Meta Hero": "0x6dc6001535e15b9def7b0f6A20a2111dFA9454E2",
  "Rumble Kong": "0xEf0182dc0574cd5874494a120750FD222FdB909a",
  "Lazy Lions": "0x8943C7bAC1914C9A7ABa750Bf2B6B09Fd21037E0",
  "The Alien Boy": "0x4581649aF66BCCAeE81eebaE3DDc0511FE4C5312",
  "Dizzy Dragons": "0x882A47e6070acA3f38Ce6929501F4787803A072b",
  "CyberKongz": "0x57a204AA1042f6E66DD7730813f4024114d74f37"
}

const projectLogos = {
  "CryptoPunks": cryptoPunksLogo,
  "BAYC": baycLogo,
  "MAYC": maycLogo,
  "BAKC": bakcLogo,
  "Pudgy Penguins": pudgyPenguinsLogo,
  "Cool Cats": coolCatsLogo,
  "Meta Hero": metaHeroLogo,
  "Rumble Kong": rumbleKongLogo,
  "Lazy Lions": lazyLionsLogo,
  "The Alien Boy": theAlienBoyLogo,
  "Dizzy Dragons": dizzyDragonsLogo,
  "CyberKongz": cyberKongzLogo,
}

const ipfsReplaceList = [
  "BAYC",
  "MAYC",
  "BAKC",
  "Meta Hero",
  "Rumble Kong"
]

const tokenUriToUrl = (tokenURI, projectName) => {
  var url
  if (ipfsReplaceList.includes(projectName)) {
    url = tokenURI.replace("ipfs://", "https://ipfs.io/ipfs/")
  }
  else {
    url = tokenURI
  }
  return url
}

const getAbi = (contractAddress) => {
  const apiKey = process.env.REACT_APP_ETHERSCAN_API_KEY
  return axios.get(
    `https://api.etherscan.io/api?module=contract&action=getabi&address=${contractAddress}&apikey=${apiKey}`
   )
}

async function getNftImage(tokenId, projectName, userAddress, web3, contractInstance) {
  if (contractInstance === undefined) {
    const contractAbi = await getAbi(projectContracts[projectName])
    contractInstance = new web3.eth.Contract(
      JSON.parse(contractAbi.data.result), projectContracts[projectName]
    )
  }

  var image
  var svgBool
  var isOwner
  if (projectName === "CryptoPunks") {
    const nftOwner = await contractInstance.methods.punkIndexToAddress(tokenId).call()
    // isOwner = userAddress.toLowerCase() === nftOwner.toLowerCase()
    isOwner = true
    if (isOwner) {
      const punkImageContractAddress = "0x16F5A35647D6F03D5D3da7b35409D65ba03aF3B2"
      const punkImageContractAbi = await getAbi(punkImageContractAddress)
      const punkImageContractInstance = new web3.eth.Contract(
        JSON.parse(punkImageContractAbi.data.result), punkImageContractAddress
      )
      const punkImageSvg = await punkImageContractInstance.methods.punkImageSvg(tokenId).call()
      image = punkImageSvg.replace("data:image/svg+xml;utf8,", "")
    }
    svgBool = true
  }
  else {
    // use 'balanceOf' function to get the supply for a give address
    // loop through and use 'tokenOfOwnerByIndex' to get all of their NFTs for that collection
    // What happens if they own 100?
    // Button to show all of them or them can search the tokenID and it will return it if they own it
    // Otherwise it will return an error message
    isOwner = true
    if (isOwner) {
      const tokenURI = await contractInstance.methods.tokenURI(tokenId).call()
      var image
      try {
        const tokenMetadata = await axios.get(tokenUriToUrl(tokenURI, projectName))
        image = tokenUriToUrl(tokenMetadata.data.image, projectName)
      }
      catch {
        const imageResponse = await axios.get(`${backend}misc/token-image-url?tokenURI=${tokenURI}`)
        image = imageResponse.data
      }
    }
    svgBool = false
  }
  return [image, svgBool, isOwner]
}

async function getMultipleNftImages(allTokenIds, projectName, userAddress, web3) {
  const contractAbi = await getAbi(projectContracts[projectName])
  const contractInstance = new web3.eth.Contract(
    JSON.parse(contractAbi.data.result), projectContracts[projectName]
  )
  const nftImages = []
  for (var i=0; i<allTokenIds.length; i++) {
    var [image, svgBool, isOwner] = await getNftImage(
      allTokenIds[i], projectName, userAddress, web3, contractInstance
    )
    nftImages.push({
      svg: svgBool ? image : undefined,
      link: svgBool ? undefined : image
    })
  }
  return nftImages
}

export {
  projectContracts,
  projectLogos,
  getNftImage,
  getMultipleNftImages
}
