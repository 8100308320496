import china from '../../../local-assets/countries/china.png'
import chinaSquare from '../../../local-assets/countries/china-square.png'
import russia from '../../../local-assets/countries/russia.png'
import russiaSquare from '../../../local-assets/countries/russia-square.png'
import thailand from '../../../local-assets/countries/thailand.png'
import thailandSquare from '../../../local-assets/countries/thailand-square.png'
import usa from '../../../local-assets/countries/usa.png'
import usaSquare from '../../../local-assets/countries/usa-square.png'
import canada from '../../../local-assets/countries/canada.png'
import canadaSquare from '../../../local-assets/countries/canada-square.png'
import japan from '../../../local-assets/countries/japan.png'
import japanSquare from '../../../local-assets/countries/japan-square.png'
import nigeria from '../../../local-assets/countries/nigeria.png'
import nigeriaSquare from '../../../local-assets/countries/nigeria-square.png'
import england from '../../../local-assets/countries/england.png'
import englandSquare from '../../../local-assets/countries/england-square.png'
import ukraine from '../../../local-assets/countries/ukraine.png'
import ukraineSquare from '../../../local-assets/countries/ukraine-square.png'
import vietnam from '../../../local-assets/countries/vietnam.png'
import vietnamSquare from '../../../local-assets/countries/vietnam-square.png'
import philippines from '../../../local-assets/countries/philippines.png'
import philippinesSquare from '../../../local-assets/countries/philippines-square.png'
import estonia from '../../../local-assets/countries/estonia.png'
import estoniaSquare from '../../../local-assets/countries/estonia-square.png'
import world from '../../../local-assets/countries/world.png'
import coreTeamToken from '../../../local-assets/aia-specific/core-team-token.png'

const countryAssets = {
  "China": china,
  "China (Square)": chinaSquare,
  "Russia": russia,
  "Russia (Square)": russiaSquare,
  "Thailand": thailand,
  "Thailand (Square)": thailandSquare,
  "USA": usa,
  "USA (Square)": usaSquare,
  "Canada": canada,
  "Canada (Square)": canadaSquare,
  "Japan": japan,
  "Japan (Square)": japanSquare,
  "Nigeria": nigeria,
  "Nigeria (Square)": nigeriaSquare,
  "England": england,
  "England (Square)": englandSquare,
  "Ukraine": ukraine,
  "Ukraine (Square)": ukraineSquare,
  "Vietnam": vietnam,
  "Vietnam (Square)": vietnamSquare,
  "Philippines": philippines,
  "Philippines (Square)": philippinesSquare,
  "Estonia": estonia,
  "Estonia (Square)": estoniaSquare,
  "Rest Of World": world,
  "Core Team": coreTeamToken
}

export {
  countryAssets
}