import React, { useEffect} from 'react'
import { FaMedium, FaDiscord, FaTwitter, FaBook } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import NetworkIdentifier from './NetworkIdentifier'
import Music from './Music'
import '../../../css/website-scaffolding/footer.css'
import * as d3 from 'd3'

const Footer = () => {
  const location = useLocation()
  const noFooterLocations = [
    "/battle",
    "/training",
    "/training-config",
    "/simulation"
  ]
  const renderBool = !noFooterLocations.includes(location.pathname)
  const solidBool = false

  useEffect(() => {
    if (solidBool) d3.select(".footer").classed("footer--solid", true)
    else d3.select(".footer").classed("footer--solid", false)
  }, [location])

  // Music goes right below network identifier
  // <Music></Music>

  const footer = (
    <div className="footer">
      <div className="footer-app-info">
        <NetworkIdentifier></NetworkIdentifier>
        <Music></Music>
      </div>

      <div className="footer-icon__container">
        <a href="https://discord.gg/aiarena" target="_blank" className="footer-icon">
          <FaDiscord/>
        </a>
        <a href="https://medium.com/@aiarena" target="_blank" className="footer-icon">
          <FaMedium/>
        </a>
        <a href="https://twitter.com/aiarena_crypto" target="_blank" className="footer-icon">
          <FaTwitter/>
        </a>
        <a href="https://docs.aiarena.io/#/" target="_blank" className="footer-icon">
          <FaBook/>
        </a>
      </div>
    </div>
  )

  return (
    <>
      {renderBool && footer}
    </>
  )
}

export default Footer
