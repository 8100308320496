import React, { useContext } from 'react'
import { Context } from "../../../Store"
import { Link, useLocation } from 'react-router-dom'
import logo from "../../../local-assets/aia-specific/aia_flat_logo_nobeta.png"
import '../../../css/website-scaffolding/navbar.css'
import * as d3 from 'd3'

const Navbar = () => {
  const [ethereumInfo, setEthereumInfo] = useContext(Context)
  const location = useLocation()
  const noNavLocations = ["/battle", "/training", "/simulation"]
  const renderBool = !noNavLocations.includes(location.pathname)

  function undoStyling() {
    document.getElementById("menu-btn").checked = false
    if (location.pathname !== "/fighter-selection")
    d3.select(".footer").classed("footer--solid", false)
  }

  const header = (
    <header>
      <a href="/"><img src={logo} alt="logo" className="logo"/></a>

      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>

      <ul className="menu">
        <li id="country-leaderboard-tab">
          <Link 
            to={{ pathname: "/leaderboard", state: "Country" }}
            onClick={undoStyling}
            replace>
            Country Leaderboard
          </Link>
        </li>
        <li id="team-leaderboard-tab">
          <Link 
            to={{ pathname: "/leaderboard", state: "Team" }}
            onClick={undoStyling}
            replace>
            Team Leaderboard
          </Link>
        </li>
        <li id="individual-leaderboard-tab">
          <Link 
            to={{ pathname: "/leaderboard", state: "Individual" }}
            onClick={undoStyling}
            replace>
            Individual Leaderboard
          </Link>
        </li>
        <li id="country-leaderboard-tab">
          {
            ethereumInfo.isCoreTeam &&
            <Link to="secret-stats" onClick={undoStyling}>SECRET</Link>
          }
      </li>
      </ul>
    </header>
  )

  return (
    <>
      {renderBool && header}
    </>
  );
};


export default Navbar;
