import {
  assetDir,
  defaultLimbPositions,
  getCharacterRenderInfo,
  attributeToDnaDivisor,
  getLimbData,
  getPositionAndSize,
  customWeaponPositionSize,
  loadAllShieldAssets,
  renderAttribute,
  specialCharacters
} from './render-helper'
import { attributeToMapping, dnaToDecimal } from './dna-mapping'
import { setComposableImage } from './composable-helper'
import rotateIconFile from '../../../local-assets/generic/rotate-icon.svg'
import * as d3 from 'd3'

export function characterRender(
    currentId, characterDna, mirrorCharacter, yOffset, xOffset, size,
    specialClass, renderCrown, inspectorBool, setFighterState, weapon,
    attachShinyGradient, composableMetaData
  ) {
    const characterIdNumber = currentId.replace("#character", "")
    const [
      characterCardWidth,
      characterCardHeight,
      characterWidth,
      characterHeight,
      iconScale,
      xBuffer,
      yBuffer,
      characterShift
    ] = getCharacterRenderInfo(size)

    const darkCharacter = currentId.includes("dark")

    var limbColor, skinColor
    if (characterDna === "default" || specialCharacters.includes(characterDna) || darkCharacter) {
      limbColor = "black"
    }

    const headDir = assetDir + "head/"
    , hairDir = assetDir + "hair/"
    , eyesDir = assetDir + "eyes/"
    , mouthDir = assetDir + "mouth/"
    , bodyDir = assetDir + "body/"
    , handsDir = assetDir + "hands/"
    , feetDir = assetDir + "feet/"

    const attributeToFiles = {
      "Head": [headDir + "head-"],
      "Hair": [hairDir + "hair-"],
      "Eyes": [eyesDir + "eyes-"],
      "Mouth": [mouthDir + "mouth-"],
      "Body": [bodyDir + "body-"],
      "Hands": [handsDir + "leftHand-", handsDir + "rightHand-"],
      "Feet": Array(2).fill(feetDir + "foot-"),
    }
    const attributeOrder = {
      "head": 3,
      "hair": 4,
      "eyes": 5,
      "mouth": 6,
      "body": 2,
      "leftHand": 8,
      "rightHand": 0,
      "leftFoot": 7,
      "rightFoot": 1
    }

    function getAttributeFiles(attribute, rarityRank) {
      var attributeIdx
      if (characterDna === "robot" || characterDna.includes("composable")) {
        if (attribute === "Head") {
          attributeIdx = characterDna
        }
        else {
          attributeIdx = "robot"
        }
      }
      else if (attribute !== "Head" && specialCharacters.includes(characterDna)) {
        if (attribute === "Eyes" && darkCharacter) {
          attributeIdx = "red-glow"
        }
        else {
          attributeIdx = characterDna
        }
      }
      else {
        if (attribute === "Eyes" && darkCharacter) {
          attributeIdx = "red-glow"
        }
        else {
          attributeIdx = dnaToDecimal(attribute, rarityRank)
        }
      }
      var rawFilesList = attributeToFiles[attribute]
      var allFiles = []
      for (var file of rawFilesList) {
        allFiles.push(file + attributeIdx + ".svg")
      }
      return allFiles
    }

    var rarityRank
    var attributeString
    var currentFileNames
    var iconPathName
    var iconName
    var svgFileNames = new Array(8)
    var svgFiles = new Array(8)

    const robotExclusions = ["Hair", "Eyes", "Mouth"]

    for (var i = 0; i < Object.keys(attributeToDnaDivisor).length + 1; i++) {
      if (i === 0) attributeString = "Head"
      else if (i === 1) attributeString = "Hair"
      else if (i === 2) attributeString = "Eyes"
      else if (i === 3) attributeString = "Mouth"
      else if (i === 4) attributeString = "Body"
      else if (i === 5) attributeString = "Hands"
      else if (i === 6) attributeString = "Feet"
      else if (i === 7) attributeString = "Limbs"
      else if (i === 8) attributeString = "Skin"

      const isRobot = characterDna === "robot" || characterDna.includes("composable")
      if (isRobot && robotExclusions.includes(attributeString)) {
        continue
      }

      if (characterDna === "default") {
        rarityRank = "x"
      }
      else {
        if (attributeString === "Head") {
          rarityRank = 0
        }
        else {
          rarityRank = (characterDna / attributeToDnaDivisor[attributeString]) % 100
        }
      }

      if (attributeString !== "Limbs" && attributeString !== "Skin") {
        currentFileNames = getAttributeFiles(attributeString, rarityRank)
        for (var [fileIdx, fileName] of currentFileNames.entries()) {
          iconPathName = fileName.split("/")
          iconName = iconPathName[iconPathName.length-1].split("-")[0]
          if (fileName.includes("feet")) {
            iconName = fileIdx === 0 ? "leftFoot" : "rightFoot"
          }
          svgFileNames[attributeOrder[iconName]] = fileName
          svgFiles[attributeOrder[iconName]] = d3.xml(fileName)
        }
      }
      else {
        var attributeIdx = dnaToDecimal(attributeString, rarityRank)
        if (attributeString === "Limbs") {
          if (attributeIdx === "x" || specialCharacters.includes(characterDna) || isRobot || darkCharacter) {
            limbColor = "black"
          }
          else {
            limbColor = attributeToMapping[attributeString][attributeIdx].color
          }
        }
        else if (attributeString === "Skin") {
          if (attributeIdx === "x") {
            skinColor = "grey"
          }
          else {
            if (specialCharacters.includes(characterDna) || isRobot) {
              skinColor = attributeToMapping[attributeString][0].color
            }
            else {
              skinColor = attributeToMapping[attributeString][attributeIdx].color
            }
          }
        }
      }
    }

    const responsiveClasses = [
      "fighter-icon",
      "drop-shadow",
      "fighter-card",
      "fighter-glow",
      "fighter-trophy",
      "fighter-laboratory",
      "fighter-simulation-option",
      "fighter-simulation-small-option",
      "fighter-training-config"
    ]

    const responsiveBool = responsiveClasses.includes(specialClass)
    const characterCardSvg = d3.select(currentId)
      .classed("svg-responsive__container", function() {
        return responsiveBool ? true : false
      })
      .style("overflow", "visible")
      .append("svg")

    var characterCardRaw
    if (responsiveBool) {
      characterCardRaw = characterCardSvg
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${characterWidth} ${characterHeight}`)
        .classed("svg-responsive__content", true)
    }
    else {
      characterCardRaw = characterCardSvg
        .attr("width", `${characterCardWidth}px`)
        .attr("height", `${characterCardHeight}px`)
    }
    const characterCard = characterCardRaw
        .attr("pointer-events", "none")
        .style("overflow", "visible")
        .append("svg")
          .attr("class", "character-object")
          .attr("width", "100%")
          .attr("height", "100%")
          .attr("x", characterWidth * characterShift)
          .style("overflow", "visible")
          .attr("pointer-events", inspectorBool ? "auto" : "none")
          .attr("cursor", inspectorBool ? "pointer" : "auto")

    if (specialClass === "drop-shadow") {
      characterCard.append("ellipse")
        .attr('cx', characterCardWidth / 2.25)
        .attr('cy', characterCardHeight * 0.98)
        .attr('rx', characterCardWidth * 0.55)
        .attr('ry', 7)
        .style('fill', '#444')
        .style("fill-opacity", 0.5)
    }

    if (attachShinyGradient) {
      var rareColorRange = ["#ffdaa3", "#92e090", "#95dbe6", "#bc9ff5", "#ffa8f3"]
      var rareColorScale = d3.scaleLinear().range(rareColorRange).domain([1, 2, 3, 4, 5])
      var linearGradient = characterCard
        .append("svg")
          .style("position", "absolute")
          .style("top", 0)
          .append("defs")
            .append("linearGradient")
            .attr("id", "super-rare-skin")
            .attr("gradientTransform", "rotate(45)")
      for (var gradIdx=0; gradIdx < 5; gradIdx++) {
        linearGradient.append("stop")
            .attr("offset", `${gradIdx*25}%`)
            .attr("stop-color", rareColorScale(gradIdx+1))
      }
    }

    const lineGenerator = d3.line()
      .x(d => d.x)
      .y(d => d.y)
      .curve(d3.curveBasis)

    const leftArmData = getLimbData(size, ...defaultLimbPositions.leftArm)
    const rightArmData = getLimbData(size, ...defaultLimbPositions.rightArm)
    const leftLegData = getLimbData(size, ...defaultLimbPositions.leftLeg)
    const rightLegData = getLimbData(size, ...defaultLimbPositions.rightLeg)

    function renderLimb(whichLimb) {
      var lineData
      if (whichLimb === "leftArm") {
        lineData = leftArmData
      }
      if (whichLimb === "rightArm") {
        lineData = rightArmData
      }
      if (whichLimb === "leftLeg") {
        lineData = leftLegData
      }
      if (whichLimb === "rightLeg") {
        lineData = rightLegData
      }
      characterCard.append("path")
        .attr('class', 'line-path')
        .attr('id', whichLimb)
        .style("fill", "none")
        .style('stroke-width', characterWidth * 0.04)
        .style('stroke', limbColor)
        .attr('d', lineGenerator(lineData))
    }

    const additionalAssets = [
      d3.xml(assetDir + "extra-effects/crown.svg"),
      d3.xml(rotateIconFile)
    ]
    if (weapon !== undefined) {
      additionalAssets.push(d3.xml(assetDir + "weapons/" + weapon + ".svg"))
    }

    const addGlowFilter = (defs, filterId) => {
      const color = d3.rgb("white")
      const matrix = "0 0 0 red 0 0 0 0 0 green 0 0 0 0 blue 0 0 0 0.5 0"
      const colorMatrix = matrix
        .replace("red", color.r / 256)
        .replace("green", color.g / 256)
        .replace("blue", color.b / 256)

      var filter = defs.append("filter")
          .attr("id",filterId)
      filter.append("feColorMatrix")
          .attr("type", "matrix")
          .attr("values", colorMatrix)
      filter.append("feGaussianBlur")
          // .attr("stdDeviation","15")
          .attr("stdDeviation","10")
          .attr("result","coloredBlur")

      var feMerge = filter.append("feMerge")
      feMerge.append("feMergeNode")
          .attr("in","coloredBlur")
      feMerge.append("feMergeNode")
          .attr("in","SourceGraphic")
    }

    Promise.all([...svgFiles, ...additionalAssets]).then(assets => {
        renderLimb("leftArm")
        renderLimb("rightArm")
        renderLimb("leftLeg")
        renderLimb("rightLeg")

        var footIdx = 0
        for (var iconIdx = 0; iconIdx < assets.length - additionalAssets.length; iconIdx++) {
          if (svgFileNames[iconIdx] === undefined) {
            continue
          }

          var icon = assets[iconIdx]
          var fullIconPath = svgFileNames[iconIdx].split("/")
          var iconName = fullIconPath[fullIconPath.length-1].replace(".svg","").split("-")[0]
          if (iconName.includes("foot")) {
            iconName = footIdx === 0 ? "leftFoot" : "rightFoot"
            footIdx += 1
          }
          var iconPosition = getPositionAndSize(iconName, characterDna)
          renderAttribute(characterCard, characterIdNumber, icon, iconName, size, ...iconPosition, false, characterDna)
          if (weapon !== undefined && iconName === "leftHand") {
            renderAttribute(characterCard, characterIdNumber, assets[assets.length-1], weapon, size, ...customWeaponPositionSize[weapon], false, characterDna)
          }
        }

        const hairFile = svgFileNames.filter(
          (name) => { return name.includes("hair") }
        )[0]
        var hairNumber
        if (hairFile !== undefined) {
          hairNumber = hairFile.split("hair-")[1].replace(".svg", "")
        }

        if (renderCrown) {
          const crownIcon = assets[svgFiles.length]
          var crownPositionY
          if (hairNumber === "1") crownPositionY = -1
          else if (hairNumber === "8") crownPositionY = -0.7
          else if (hairNumber === "3" || hairNumber === "6") crownPositionY = -0.85
          else if (hairNumber === "0" || hairNumber == "9") crownPositionY = -0.8
          else crownPositionY = -0.75
          renderAttribute(
            characterCard, characterIdNumber, crownIcon, "crown", size, 0.2, crownPositionY, 0.6, false, characterDna
          )
        }

        characterCard.select("#rightLeg").raise()
        characterCard.select(`#rightFoot-${characterIdNumber}`).raise()
        characterCard.select("#leftLeg").raise()
        characterCard.select(`#body-${characterIdNumber}`).raise()
        characterCard.select("#leftArm").raise()
        characterCard.select(`#leftFoot-${characterIdNumber}`).raise()
        characterCard.select(`#head-${characterIdNumber}`).raise()
        characterCard.select(`#mouth-${characterIdNumber}`).raise()
        characterCard.select(`#hair-${characterIdNumber}`).raise()
        characterCard.select(`#eyes-${characterIdNumber}`).raise()
        characterCard.select(`#leftHand-${characterIdNumber}`).raise()

        const defaultSkinColor = "rgb(255, 204, 170)"
        const skinColorPaths = characterCard.selectAll("path").filter(
          function(d){ return d3.select(this).style('fill') === defaultSkinColor }
        )
        skinColorPaths.style("fill", skinColor)

        if (yOffset === undefined) {
          yOffset = (characterCardHeight - iconScale) / 2
        }
        if (xOffset === undefined) {
          xOffset = (characterCardWidth - iconScale) / 2
        }

        if (specialClass !== undefined) {
          if (specialClass === "fighter-glow") {
            var defs = characterCard.append("defs")
            addGlowFilter(defs, "fighter-glow")
            characterCard.style("filter", "url(#fighter-glow)")
          }
          else {
            d3.select(currentId).classed(specialClass, true)
          }
        }

        d3.select(currentId)
          .style("position", "absolute")
          .style("bottom", yOffset)
          .style("left", xOffset)

        const scaleValueX = mirrorCharacter ? "-1" : "1"
        d3.select(currentId).select("svg")
          .attr("transform", `scale(${scaleValueX},1)`)
          .attr("style", `-webkit-transform: scale(${scaleValueX},1); overflow: visible`)

        loadAllShieldAssets(d3.select(`#character${characterIdNumber}`), characterIdNumber, size)

        if (characterDna.includes("composable") && composableMetaData !== undefined) {
          if (composableMetaData.image !== undefined) {
            const headId = `#head-${currentId.replace("#character", "")}`
            setComposableImage(
              headId,
              {
                svg: composableMetaData.isSvg ? composableMetaData.image : undefined,
                link: composableMetaData.isSvg ? undefined : composableMetaData.image
              },
              composableMetaData
            )  
          }
        }
        
        if (darkCharacter) {
          d3.select(currentId).select(".character-object").selectAll("svg").nodes().forEach((node) => {
            if (node.id !== "" && !node.id.includes("eyes")) {
              var selection = d3.select("#" + node.id)
              if (node.id.includes("head")) {
                if (characterDna.includes("composable")) {                  
                  // var defs = selection.select("#helmet-filling").append("defs")
                  // addGlowFilter(defs, "composable-helmet-glow")
                  // selection.select("#helmet-filling").style("filter", "url(#composable-helmet-glow)")
                  selection = selection.selectAll("path:not(#helmet-filling)")
                }
                else {
                  selection = selection.select("#path49")
                }
              }
              selection.style("filter", "grayscale(100%) brightness(10%)")
            }
          })
        }
          

        // // Add border to track collisions
        // const characterBoundingBox = characterCard.node().getBBox()
        // characterCard.append("rect")
     		// 	.attr("x", characterBoundingBox.x)
     		// 	.attr("y", characterBoundingBox.y)
     		// 	.attr("height", characterBoundingBox.height)
     		// 	.attr("width", characterBoundingBox.width)
     		// 	.style("stroke", "black")
     		// 	.style("fill", "none")
     		// 	.style("stroke-width", 2)
    })
}
