import Main from "./js/index"
import Store from "./Store";

function App() {
  return (
    <Store>
      <Main></Main>
    </Store>
  );
}

export default App;
