const getTableStats = (battleRecord) => {
  const wins = parseInt(battleRecord[0])
  const ties = parseInt(battleRecord[1])
  const loses = parseInt(battleRecord[2])
  const gamesPlayed = wins + ties + loses
  const score = wins * 3 + ties

  return {
    gamesPlayed: gamesPlayed,
    winRate: wins / gamesPlayed,
    score: score
  }
}

export {
  getTableStats
}
