import React, { useEffect, useContext } from "react"
import { Context } from "../Store"
import { HashRouter as Router, Route, Switch } from "react-router-dom"
import FighterFarmNoChainlink from "../abis/FighterFarmNoChainlink.json"
import RankedBattle from "../abis/RankedBattle.json"
import NeuralNetwork from "../abis/NeuralNetwork.json"
import Multicall from "../abis/Multicall.json"

import Home from "./web3-components/website-scaffolding/Home"
import Navbar from "./web3-components/website-scaffolding/Navbar"
import Footer from "./web3-components/website-scaffolding/Footer"
// import TokenContainer from "./web3-components/token-display-components/TokenContainer"
import LeaderboardContainer from "./web3-components/leaderboard-components/LeaderboardContainer"
import SecretStats from "./web3-components/secret-stats-components/SecretStats"

import { networkIdToData } from "./helpers/misc/ethereum-networks"

import Error from "./generic-components/Error"
import UnderConstruction from "./generic-components/UnderConstruction"
import Web3 from "web3"
import axios from "axios"
require("dotenv").config()

const ReactRouterSetup = () => {
  const [ethereumInfo, setEthereumInfo] = useContext(Context)
  const backend = process.env.REACT_APP_BACKEND

  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      )
    }
  }

  async function loadBlockchainData(accounts, networkId) {
    const web3 = window.web3
    if (accounts === undefined) {
      accounts = await web3.eth.getAccounts()
    }
    if (networkId === undefined) {
      networkId = await web3.eth.net.getId()
    }

    web3.eth.clearSubscriptions()
    const networkName = networkIdToData[networkId]
    const fighterFarmData = FighterFarmNoChainlink.networks[networkId]
    const rankedBattleData = RankedBattle.networks[networkId]
    const neuralNetworkData = NeuralNetwork.networks[networkId]

    const hasTeamAccess = await axios.get(
      `${backend}whitelist/is-core-team?ethereumAddress=${accounts[0]}`
    )

    const connectionAlertMessage =
      "Our smart contracts are not deployed to the detected network. Please switch to the Rinkeby testnet and refresh the page."

    var contractDeployedToNetwork = false
    if (rankedBattleData) {
      const rankedBattleContract = new web3.eth.Contract(
        RankedBattle.abi,
        rankedBattleData.address
      )
      if (neuralNetworkData) {
        const neuralNetworkContract = new web3.eth.Contract(
          NeuralNetwork.abi,
          neuralNetworkData.address
        )
        if (fighterFarmData) {
          const fighterFarm = new web3.eth.Contract(
            FighterFarmNoChainlink.abi,
            fighterFarmData.address
          )

          const totalSupply = await fighterFarm.methods.totalSupply().call()
          const tokenIds = []
          for (var i = 0; i < totalSupply; i++) {
            tokenIds.push(i)
          }

          const newEthereumInfo = {
            web3: web3,
            network: networkId,
            networkName: networkName,
            smartContract: fighterFarm,
            smartContractAddress: fighterFarmData.address,
            rankedBattleContract: rankedBattleContract,
            rankedBattleContractAddress: rankedBattleData.address,
            neuralNetworkContract: neuralNetworkContract,
            musicMuted: true,
            songPlaying: undefined,
            volumeSettings: 0.5,
            isCoreTeam: hasTeamAccess.data.access,
            totalSupply: totalSupply,
            tokenIds: tokenIds,
            multicallContract: new web3.eth.Contract(
              Multicall, 
              "0x5D6e06d3E154C5DBEC91317f0d04AE03AB49A273" // Arbitrum Rinkeby
            )
          }
          contractDeployedToNetwork = true
          setEthereumInfo(newEthereumInfo)
        }
      }
    }
    if (!contractDeployedToNetwork) {
      setEthereumInfo((prevState) => {
        return {
          ...prevState,
          web3: undefined,
          network: networkId,
        }
      })
      window.alert(connectionAlertMessage)
    }
  }

  useEffect(() => {
    loadWeb3()
    if (window.web3 !== undefined) {
      loadBlockchainData(undefined, undefined)
      window.ethereum.on("chainChanged", function (networkId) {
        loadBlockchainData(undefined, networkId)
      })
    }
  }, [])

  // Used HashRouter instead of Router since we use hashes in IPFS
  const underConstruction = false
  return (
    <>
      <Router>
        <div className="appContent">
          <Navbar></Navbar>
          {
            !underConstruction &&
            <Switch>
              <Route exact path="/">
                <Home></Home>
              </Route>

              <Route exact path="/leaderboard">
                <LeaderboardContainer></LeaderboardContainer>
              </Route>

              {
                ethereumInfo.isCoreTeam &&
                <Route exact path="/secret-stats">
                  <SecretStats></SecretStats>
                </Route>
              }

              <Route path="*">
                <Error></Error>
              </Route>
            </Switch>
          }

          {
            underConstruction &&
            <Switch>
              <Route exact path="/">
                <Home></Home>
              </Route>
              <Route path="*">
                <UnderConstruction></UnderConstruction>
              </Route>
            </Switch>
          }
        </div>
        <Footer></Footer>
      </Router>
    </>
  )
}

//<Route
//exact
//path="/token/:id"
//children={<TokenContainer></TokenContainer>}
//></Route>

export default ReactRouterSetup
