import { singlePunchAnimation } from '../animations/live-interaction-moves/single-punch'
import { doublePunchAnimation } from '../animations/live-interaction-moves/double-punch'
import { startDefendAnimation, endDefendAnimation } from '../animations/live-interaction-moves/defend'
import { lowKickAnimation } from '../animations/live-interaction-moves/low-kick'
import card from '../../../local-assets/generic/card-pack.png'
import tokenPlatform from '../../../local-assets/token-display/display-platform.png'
import stagePlatform from '../../../local-assets/token-display/podium_techy_base.png'

const defaultCharInputs = {
  xOffset: "48%",
  yOffset: "36%",
  mirror: false,
  size: 1,
  specialClass: "fighter-simulation-option"
}

const battleCharInputs = {
  size: 1,
  specialClass: "fighter-simulation-small-option"
}

const defendAnimation = (id, allTimeouts) => {
  startDefendAnimation(id, allTimeouts)
  setTimeout(() => {
    endDefendAnimation(id, allTimeouts)
  }, 350)
}

const statsBuckets = [
  {
    description: 'USERS IN OUR ARENA',
    platform: tokenPlatform,
    platformClass: "token-platform--landing-page",
    chars: [{
      ...defaultCharInputs,
      battleAttributes: {
        power: 50,
        speed: 50,
        defence: 50,
        accuracy: 50,
      },
      dna: "21547430148493972150960002141903396010882792664219020193466910589169390006919",
      id: "users-in-arena",
      type: "Fire"
    }],
    animationFunction: doublePunchAnimation,
    stat: '000',
  },
  {
    description: 'NFT FIGHTERS',
    src: card,
    stat: '000',
  },
  {
    description: 'SESSIONS TRAINED',
    platform: tokenPlatform,
    platformClass: "token-platform--landing-page",
    chars: [{
      ...defaultCharInputs,
      battleAttributes: {
        power: 50,
        speed: 50,
        defence: 50,
        accuracy: 50,
      },
      dna: "21527430148443972150960002141903396010882792664219090193466910589169390006919",
      id: "sessions-trained",
      type: "Fire"
    }],
    animationFunction: lowKickAnimation,
    stat: '000',
  },
  {
    description: 'BATTLES FOUGHT',
    platform: stagePlatform,
    platformClass: "stage-platform--landing-page",
    chars: [
      {
        ...battleCharInputs,
        xOffset: "30%",
        yOffset: "38%",
        mirror: false,
        battleAttributes: {
          power: 50,
          speed: 50,
          defence: 50,
          accuracy: 50,
        },
        dna: "21527430148443972150960002141903396010882792664219090193466910589169390006919",
        id: "battles-fought-1",
        type: "Fire"
      },
      {
        ...battleCharInputs,
        xOffset: "70%",
        yOffset: "38%",
        mirror: true,
        battleAttributes: {
          power: 50,
          speed: 50,
          defence: 50,
          accuracy: 50,
        },
        dna: "21527430148443972150960002141903396010882792664219090193466910589169390006919",
        id: "battles-fought-2",
        type: "Fire"
      },
    ],
    animationFunction: [singlePunchAnimation, defendAnimation],
    stat: '000',
  },
]

export {
  statsBuckets
}