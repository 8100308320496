import React, { useState, useEffect, useContext } from 'react'
import { Context } from "../../../Store"
import { useHistory } from "react-router-dom"
import LeaderboardTable from './LeaderboardTable'
import CountryLeaderboardTable from './CountryLeaderboardTable'
import TeamLeaderboardTable from './TeamLeaderboardTable'
import FighterPodium from '../shared-components/FighterPodium'
import SubComponentLoader from '../../generic-components/SubComponentLoader'
import { getTableStats } from '../../helpers/misc/battle-performance'
import { truncateName } from '../../helpers/misc/formatting'
import { attachComposableMetadata } from '../../helpers/asset-handling/composable-helper'
import { getFighterValuesFromBlockchain, createFighterObject } from '../../helpers/asset-handling/create-fighter'
import '../../../css/game-play/leaderboard.css'
import axios from 'axios'
import * as d3 from 'd3'
require('dotenv').config()

const Leaderboard = ({ leaderboardType }) => {
  const history = useHistory()
  const [ethereumInfo, setEthereumInfo] = useContext(Context)
  const [countryRep, setCountryRep] = useState({})
  const [teamRep, setTeamRep] = useState({})
  const [countryRepFighters, setCountryRepFighters] = useState({})
  const [teamRepFighters, setTeamRepFighters] = useState({})
  const [countryStats, setCountryStats] = useState({})
  const [teamStats, setTeamStats] = useState({})
  const [allFighters, setAllFighters] = useState([])
  const [fighterHighlight, setFighterHighlight] = useState(undefined)
  const [selectedFighter, setSelectedFighter] = useState(undefined)
  const [leaderboardData, setLeaderboardData] = useState([])
  const [filterValues, setFilterValues] = useState({id: "", type: ""})
  const [pageNumber, setPageNumber] = useState({page: 0, highlight: undefined})
  const [pageCount, setPageCount] = useState(1)

  const countryTeamLeaderboard = leaderboardType !== "Individual"

  const nTopFighters = 10
  const charactersPerPage = 6
  const charactersSeen = pageNumber.page * charactersPerPage
  const numFighters = "all"
  const backend = process.env.REACT_APP_BACKEND

  const changePage = ({ selected, id }) => {
    setPageNumber({page: selected, highlight: id})
    if (id === undefined) {
      setFighterHighlight(undefined)
    }
  }

  const sigmoid = (x) => {
    return 1 / (1 + Math.exp(-x))
  }

  const selectFighter = (id) => {
    d3.select("#choose-fighter__options").style("display", "none")
    d3.selectAll(".fighter-option").classed("chosen-fighter-option", false)
    d3.select(`#fighter-option-${id}`).classed("chosen-fighter-option", true)
    setFighterHighlight(id)
    d3.select("#podium-popup-open").style("display", "block")
    for (var fighterRank = 0; fighterRank < allFighters.length; fighterRank++) {
      if (allFighters[fighterRank].id.toString() === id.toString()) {
        break
      }
    }
    const whichPage = Math.floor(fighterRank / charactersPerPage)
    if (whichPage === pageNumber.page) {
      d3.selectAll(".leaderboard__row").classed("chosen-row", false)
      d3.select(`#row-${id}`).classed("chosen-row", true)
    }
    changePage({ selected: whichPage, id: id })
    setFilterValues((prevState) => {
      const newState = {}
      Object.keys(prevState).forEach(filterKey => {
        newState[filterKey] = ""
      })
      return newState
    })
  }

  async function getAllFighters() {
    const fightersResult = await axios.get(
      `${backend}fighters/leaderboard?numFighters=${numFighters}&network=${ethereumInfo.networkName}`
    )
    setAllFighters(fightersResult.data)
    setPageCount(Math.ceil(fightersResult.data.length / charactersPerPage))
  }

  async function getCountryTeamRep() {
    const countryTeamRepResult = await axios.get(`${backend}whitelist/representing-teams`)
    const groupingData = countryTeamRepResult.data

    const tempCountryRep = {}
    const tempTeamRep = {}
    Object.keys(groupingData).forEach((address) => { 
      tempCountryRep[address] = groupingData[address].community
      tempTeamRep[address] = groupingData[address].team
    })
    setCountryRep(tempCountryRep)
    setTeamRep(tempTeamRep)
  }

  const addFighterToCountry = (fighters, country, fighter, score, collectedIds) => {
    collectedIds.push(parseInt(fighter.id))
    country = country !== undefined ? country : "Core Team"

    const gamesPlayed = fighter.battleRecord.reduce(
      (partialSum, x) => partialSum + parseInt(x), 0
    )
    const gamesPlayedMultiplier = sigmoid(gamesPlayed / 10)
    const fighterData = {
      character: fighter,
      wins: parseInt(fighter.battleRecord[0]),
      gamesPlayed: gamesPlayed,
      points: score * gamesPlayedMultiplier,
    }

    if (fighters[country] === undefined) {      
      fighters[country] = [fighterData]
    }
    else {
      fighters[country].push(fighterData)
    }
    return fighters
  }

  const aggregateData = (fighters, aggregateStats) => {
    Object.keys(fighters).forEach((teamName) => {
      fighters[teamName].sort(function(a, b) {
        return b.points - a.points
      })
      var sum = 0
      var gamesPlayed = 0
      var gamesWon = 0
      const teamFightersSubset = fighters[teamName].slice(0, nTopFighters)
      teamFightersSubset.forEach((ftr) => {
        sum += ftr.points
        gamesPlayed += ftr.gamesPlayed
        gamesWon += ftr.wins
      })
      aggregateStats[teamName] = {
        wins: gamesWon,
        numFighters: teamFightersSubset.length,
        gamesPlayed: gamesPlayed,
        score: sum
      }
    })
    return aggregateStats
  }

  async function aggregateCountryScores() {      
    var countryFighters = {}
    var countryAggregateStats = {}
    var teamFighters = {}
    var teamAggregateStats = {}
    
    const multicallArgs = ethereumInfo.tokenIds.map((tokenid) => ({
      target: ethereumInfo.smartContractAddress,
      callData: ethereumInfo.smartContract.methods.ownerOf(tokenid).encodeABI(),
    }))
    const ownerOfs = await ethereumInfo.multicallContract.methods.aggregate(multicallArgs).call();
    
    const multicallArgsRecords = ethereumInfo.tokenIds.map((tokenid) => ({
      target: ethereumInfo.rankedBattleContractAddress,
      callData: ethereumInfo.rankedBattleContract.methods.getBattleRecord(tokenid).encodeABI(),
    }))
    const battleRecords = await ethereumInfo.multicallContract.methods.aggregate(multicallArgsRecords).call();    

    var eloSum = 0

    var owner
    var battleRecordHex
    var battleRecord
    var ftr
    for (var i = 0; i < allFighters.length; i++) {
      ftr = allFighters[i]
      owner = ownerOfs.returnData[ftr.id].replace("0x000000000000000000000000", "0x")
      battleRecordHex = battleRecords.returnData[ftr.id].replace("0x", "")
      battleRecord = [
        parseInt(battleRecordHex.slice(0,64), 16), 
        parseInt(battleRecordHex.slice(64,128), 16), 
        parseInt(battleRecordHex.slice(128,192), 16)
      ];
      countryFighters = addFighterToCountry(
        countryFighters, 
        countryRep[owner.toLowerCase()], 
        {...ftr, battleRecord: battleRecord}, 
        ftr.score, 
        []
      );
      teamFighters = addFighterToCountry(
        teamFighters, 
        teamRep[owner.toLowerCase()], 
        {...ftr, battleRecord: battleRecord}, 
        ftr.score, 
        []
      );

      eloSum += ftr.score
    }

    console.log(eloSum / allFighters.length)

    countryAggregateStats = aggregateData(countryFighters, countryAggregateStats)
    teamAggregateStats = aggregateData(teamFighters, teamAggregateStats)

    setCountryStats(countryAggregateStats);
    setCountryRepFighters(countryFighters);
    
    setTeamStats(teamAggregateStats);
    setTeamRepFighters(teamFighters);
  }

  async function getLeaderboardData(filteredFighters) {
    var tokenId
    var tableStats
    var currentFighter
    var topFightersData = []

    filteredFighters = filteredFighters !== undefined ? filteredFighters : [...allFighters]
    const subsetFighters = filteredFighters.slice(charactersSeen, charactersSeen + charactersPerPage)

    for (var i = 0; i < subsetFighters.length; i++) {
      tokenId = subsetFighters[i].id
      currentFighter = await getFighterValuesFromBlockchain(
        ethereumInfo.smartContract, ethereumInfo.rankedBattleContract, tokenId
      )
      tableStats = getTableStats(currentFighter.battleRecord)
      currentFighter.id = tokenId

      currentFighter = await attachComposableMetadata(currentFighter, tokenId, ethereumInfo)
      const ownerUsernameResult = await axios.get(
        `${backend}users/username?address=${currentFighter.owner}`
      )
      
      const charCountry = countryRep[currentFighter.owner.toLowerCase()]
      const ownerUsername = ownerUsernameResult.data[0].username
      topFightersData.push({
        character: {...createFighterObject(currentFighter.owner, currentFighter), country: charCountry},
        country: charCountry !== undefined ? charCountry : "Core Team",
        id: tokenId,
        gamesPlayed: tableStats.gamesPlayed,
        winRate: tableStats.winRate,
        score: subsetFighters[i].score,
        onSale: currentFighter.onSale,
        rank: charactersSeen + i + 1,
        username: ownerUsername !== "" ? truncateName(ownerUsername) : truncateName(currentFighter.owner)
      })
    }
    setLeaderboardData(topFightersData)
  }

  const routeToToken = (char) => {
    if (char !== undefined) {
      history.push(`/token/${char.id}`)
    }
  }

  const getFilteredFighters = () => {
    var filteredFighters = [...allFighters]
    if (filterValues.id !== "") {
      filteredFighters = allFighters.filter((char) => char.id === parseInt(filterValues.id))
    }
    return filteredFighters
  }

  const removeFighterHighlight = () => {
    d3.select("#selected-fighter__container").style("display", "none")
    d3.select("#podium-popup-open").style("display", "none")
  }

  const resizeHandle = () => {
    const screenWidth = document.body.clientWidth
    const screenHeight = document.body.clientHeight
    const wideView = screenWidth / screenHeight > 1
    if (wideView) {
      d3.select("#selected-fighter__container").style("display", "flex")
    }
    else if (!wideView && d3.select("#podium-popup-open").style("display") === "none") {
      d3.select("#selected-fighter__container").style("display", "none")
    }
  }

  const getHighlight = () => {
    const highlight = leaderboardData.filter((char) => char.id === parseInt(fighterHighlight))
    return [highlight, highlight.length > 0]
  }

  useEffect(() => {
    getCountryTeamRep()
    getAllFighters()
    resizeHandle()
    window.addEventListener("resize", resizeHandle)
    return () => {
      window.removeEventListener("resize", resizeHandle)
    }
  }, [])

  useEffect(() => {
    if (Object.keys(countryRep).length > 0 && leaderboardData.length > 0) {
      aggregateCountryScores()
    }
  }, [countryRep, leaderboardData])

  useEffect(() => {
    if (Object.keys(countryRep).length > 0 && allFighters.length > 0) {
      getLeaderboardData(getFilteredFighters())
    }
  }, [countryRep, allFighters, pageNumber.page])

  useEffect(() => {
    if (allFighters.length > 0) {
      const filteredFighters = getFilteredFighters()
      const newPageCount = Math.ceil(filteredFighters.length / charactersPerPage)
      if (newPageCount !== pageCount) {
        setPageCount(newPageCount)
        if (newPageCount < pageCount) {
          changePage({ selected: 0, id: undefined })
        }
      }
      getLeaderboardData(filteredFighters)
    }
  }, [filterValues])

  useEffect(() => {
    const [highlight, exists] = getHighlight()
    if (exists) {
      setSelectedFighter(highlight[0])
    }
  }, [fighterHighlight, leaderboardData])

  useEffect(() => {
    if (getHighlight()[1] && d3.select("#selected-fighter__container").style("display") === "none") {
      d3.select("#selected-fighter__container").style("display", "flex")
    }
  }, [fighterHighlight])

  useEffect(() => {
    if (pageNumber.highlight !== undefined) {
      d3.selectAll("tr").classed("chosen-row", false)
      d3.select(`#row-${pageNumber.highlight}`).classed("chosen-row", true)
    }
  }, [leaderboardData])

  const showButtonFunction = (char) => {
    return false
  }

  return (
    <>
      <div id="podium-popup-open" style={{display: "none"}}></div>
      <div className="leaderboard-page__container">
        <div className="leaderboard-filter__container"
             style={{width: countryTeamLeaderboard ? "100vw" : "55vw"}}>
          {
            (
              (leaderboardData.length === 0 && leaderboardType === "Individual") ||
              (Object.keys(countryRepFighters).length === 0 && leaderboardType === "Country") ||
              (Object.keys(teamRepFighters).length === 0 && leaderboardType === "Team")
            ) &&
            <SubComponentLoader displayText={!countryTeamLeaderboard}></SubComponentLoader>
          }
          {
            leaderboardData.length > 0 && leaderboardType === "Individual" &&
            <>
              <LeaderboardTable
                leaderboardData={leaderboardData}
                selectFighter={selectFighter}
                pageCount={pageCount}
                changePage={changePage}
                pageNumber={pageNumber}
                charactersPerPage={charactersPerPage}>
              </LeaderboardTable>
            </>
          }
          {
            Object.keys(countryRepFighters).length > 0 && leaderboardType === "Country" &&
            <>
              <CountryLeaderboardTable
                countryAggregateStats={countryStats}
                pageCount={Math.ceil(Object.keys(countryRepFighters).length / charactersPerPage)}
                changePage={changePage}
                pageNumber={pageNumber}
                charactersPerPage={charactersPerPage}>
              </CountryLeaderboardTable>
            </>
          }
          {
            Object.keys(teamRepFighters).length > 0 && leaderboardType === "Team" &&
            <>
              <TeamLeaderboardTable
                teamAggregateStats={teamStats}
                pageCount={Math.ceil(Object.keys(teamRepFighters).length / charactersPerPage)}
                changePage={changePage}
                pageNumber={pageNumber}
                charactersPerPage={charactersPerPage}>
              </TeamLeaderboardTable>
            </>
          }
        </div>

        {
          !countryTeamLeaderboard &&
          <div id="selected-fighter__container">
            <div id="selected-fighter__info">
              {
                selectedFighter !== undefined &&
                <>
                  <h2>FIGHTER ID: {selectedFighter.id}</h2>
                  <h2>OWNER: {selectedFighter.username}</h2>
                  <div id={`for-sale-indicator--false`} className="for-sale-indicator">
                    <h1>{selectedFighter.country}</h1>
                  </div>
                  <div id="selected-fighter__info--exit"
                      onClick={() => removeFighterHighlight()}>
                    x
                  </div>
                </>
              }
            </div>
            <FighterPodium
              char={selectedFighter !== undefined ? selectedFighter.character : undefined}
              showButtonFunction={showButtonFunction}
              buttonText="VIEW"
              buttonFunction={routeToToken}
              buttonInputs={[selectedFighter !== undefined ? selectedFighter.character : undefined]}
              customButtonStyling={{fontSize: "2.2vw", padding: "0.3vw 1vw", borderRadius: "0.5vw"}}>
            </FighterPodium>
          </div>
        }
      </div>

    </>
  )
}

export default Leaderboard
