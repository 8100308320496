import React, { useState, useEffect, useContext, useRef } from 'react'
import { Context } from "../../../Store"
import Character from '../../generic-components/Character'
import { idleAnimation } from '../../helpers/animations/live-interaction-moves/idle-animation'
import { statsBuckets } from '../../helpers/stats-aggregation/landing-page-stats'
import '../../../css/generic-styling/statistics.css'
import * as d3 from 'd3'

const Statistics = () => {
  const [ethereumInfo, setEthereumInfo] = useContext(Context)
  const refContainer = useRef({})
  const [stats, setStats] = useState({
    'USERS IN OUR ARENA': undefined,
    'NFT FIGHTERS': undefined,
    'SESSIONS TRAINED': undefined,
    'BATTLES FOUGHT': undefined,
  })

  function removeDuplicates(allAddresses) {
    var uniqueAddresses = []
    allAddresses.forEach((address) => {
      if (!uniqueAddresses.includes(address)) {
        uniqueAddresses.push(address)
      }
    })
    return uniqueAddresses
  }

  async function getNumOwners(smartContract, smartContractAddress, web3) {
    const multicallArgs = ethereumInfo.tokenIds.map((tokenid) => ({
      target: smartContractAddress,
      callData: smartContract.methods.ownerOf(tokenid).encodeABI(),
    }))
    const ownerOfs = await ethereumInfo.multicallContract.methods.aggregate(multicallArgs).call()

    return removeDuplicates(ownerOfs[1]).length
  }

  async function getNumFighters(smartContract) {
    return smartContract.methods.totalSupply().call()
  }

  async function getNumBattles(smartContract) {
    return smartContract.methods.getTotalBattles().call()
  }

  async function getNumTraining(smartContract) {
    const trainingStats = await smartContract.methods.getTotalTrainingStats().call()
    return +trainingStats[0] + +trainingStats[1] + +trainingStats[2]
  }

  const descToFunc = {
    'USERS IN OUR ARENA': getNumOwners,
    'NFT FIGHTERS': getNumFighters,
    'SESSIONS TRAINED': getNumTraining,
    'BATTLES FOUGHT': getNumBattles,
  }


  async function getStats() {
    const numOwners = await descToFunc['USERS IN OUR ARENA'](
      ethereumInfo.smartContract, ethereumInfo.smartContractAddress, ethereumInfo.web3
    )
    const numFighters = await descToFunc['NFT FIGHTERS'](ethereumInfo.smartContract)
    const numBattles = await descToFunc['BATTLES FOUGHT'](ethereumInfo.rankedBattleContract)
    const numSessionsTrained = await descToFunc['SESSIONS TRAINED'](ethereumInfo.neuralNetworkContract)
    
    setStats({
      'USERS IN OUR ARENA': numOwners,
      'NFT FIGHTERS': numFighters,
      'SESSIONS TRAINED': numSessionsTrained,
      'BATTLES FOUGHT': numBattles,
    })
  }

  useEffect(() => {
    if (ethereumInfo.smartContract !== undefined) {
      getStats()
    }
    
    statsBuckets.forEach((stat) => {
      const containerId = refContainer.current[stat.description].id
      d3.select(`#${containerId}`)
        .on("mouseover", function() {
          const charDOM = d3.select(this).selectAll(".character")
          if (charDOM._groups[0][0] !== null && charDOM._groups[0][0] !== undefined) {
            var charId
            if (charDOM._groups[0].length > 1) {
              charDOM.nodes().forEach((currentDOM, idx) => {
                charId = currentDOM.id.replace("character", "")
                stat.animationFunction[idx](charId, [])
              })
            }
            else {
              charId = charDOM.attr("id").replace("character", "")
              stat.animationFunction(charId, [])
            }            
          }
        })
        .on("mouseout", function() {
          const charDOM = d3.select(this).select(".character")
          if (charDOM._groups[0][0] !== null && charDOM._groups[0][0] !== undefined) {
            const charId = charDOM.attr("id").replace("character", "")
            idleAnimation(charId)
          }
        })
    })
  }, [ethereumInfo])

  return (
    <div className="statistics__container">
      {statsBuckets.map((stat, index) => {
        return (
          <div
            key={`cpu-${index}`}
            className="statistic--individual"
            id={`statistic-${index}`}
            ref={
              ref => refContainer.current[stat.description] = ref
            }
          >
            <div className="statistic--visual">
              {
                stat.platform !== undefined &&
                <img src={stat.platform} className={stat.platformClass} alt="" />
              }
              {
                stat.chars !== undefined &&
                stat.chars.map((char, k) => {
                  return <Character key={`char-${k}`} {...char}></Character>
                })
                
              }
              {
                stat.chars === undefined &&
                <img className="card-pack" src={stat.src} id="img" />
              }
            </div>
            <div className="statistic--level">
              <p className="stat">{stats[stat.description]}</p>
              <h2 className="text">{stat.description}</h2>
            </div>
          
          </div>
        )
      })}
    </div>
  )
}

export default Statistics
