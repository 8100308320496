import React, { useEffect, useRef } from 'react'
import { renderSimpleLoader } from '../helpers/animations/simple-loader.js'
import "../../css/generic-styling/loader.css"

const Loader = ({ repeatBool, smallLoaderBool }) => {
  const refContainer = useRef()
  const loaderClass = smallLoaderBool ? "simple-loader-small" : "simple-loader"

  useEffect(() => {
    renderSimpleLoader(repeatBool)
  }, [])

  return (
    <>
      <div className="loader__container">
        <div id="simple-loader" className={loaderClass} ref={refContainer}></div>
      </div>
    </>
  );
};

export default Loader;
