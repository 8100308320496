import React, { useEffect } from 'react'
import spinningLoader from '../../local-assets/aia-specific/aia-token-spinning.png'
import "../../css/generic-styling/loader.css"
import * as d3 from 'd3'

const loadingEllipsis = (nDots) => {
  const loadingFlagGroup = d3.select("#loading-data-flag")._groups[0][0]
  if (loadingFlagGroup !== null && loadingFlagGroup !== undefined) {
    d3.selectAll(".loading__ellipsis").text(".".repeat(nDots))
    if (d3.select("#loading-data-flag").style("display") === "block") {
      setTimeout(() => {
        loadingEllipsis((nDots + 1) % 4)
      }, 600)
    }
  }
  return
}

const SubComponentLoader = ({ displayText }) => {
  useEffect(() => {
    loadingEllipsis(0)
  }, [])

  return (
    <>
      <div id="loading-data-flag" style={{display: "block"}}></div>
      <div className="spinning-loader__container">
        <img className="spinning-loader" src={spinningLoader} alt="" />
        {
          displayText &&
          <h1 id="battle-loading__text">
            Loading <span className="loading__ellipsis"></span>
          </h1>
        }
      </div>
    </>
  );
};

export default SubComponentLoader;
export {
  loadingEllipsis
}
