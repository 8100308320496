import React, { useState, useEffect, useContext } from "react"
import { Context } from "../../../Store"
import { useLocation } from "react-router-dom"
import { musicAssets } from "../../../js/helpers/asset-files/music-mapping"
import muteIcon from "../../../local-assets/generic/mute.svg"
import muteIconDark from "../../../local-assets/generic/mute-dark.svg"
import volumeIcon from "../../../local-assets/generic/volume.svg"
import volumeIconDark from "../../../local-assets/generic/volume-dark.svg"
import "../../../css/website-scaffolding/footer.css"
import * as d3 from "d3"

const Music = ({ startMusicOn, darkBool }) => {
  const [ethereumInfo, setEthereumInfo] = useContext(Context)
  const location = useLocation()
  const [randomizedSongs, setRandomizedSongs] = useState({ rankedBattle: 1 })
  startMusicOn = startMusicOn !== undefined ? startMusicOn : false

  const getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min)
  };

  const musicTimeouts = []
  const clearMusictimeouts = () => {
    var currentTimeout
    while (musicTimeouts.length > 0) {
      currentTimeout = musicTimeouts.pop()
      clearTimeout(currentTimeout)
    }
  }

  const pathToSongId = {
    "/": "homeMusic",
    "/collection": "collectionMusic",
    "/leaderboard": "leaderboardMusic",
    "/marketplace": "marketMusic",
    "/fighter-selection": "fighterSelectionMusic",
    "/training-modes": "fighterSelectionMusic",
    "/gameplay-menu": "fighterSelectionMusic",
    "/simulation-options": "fighterSelectionMusic",
    "/opponent-selection": "fighterSelectionMusic",
    "/battle": "ranked-battle-" + randomizedSongs.rankedBattle,
    "/simulation": "simulationMusic",
    "/training": "trainingMusic",
  }

  const getSongName = () => {
    var songName
    if (location.pathname.includes("ai-inspector")) {
      songName = "inspectorMusic"
    } else {
      songName = pathToSongId[location.pathname]      
    }
    return songName
  }

  const getSong = (songName) => {
    if (songName === undefined) {
      songName = getSongName()
    }
    return musicAssets[songName]
  };

  const fadeOutMusic = (song) => {    
    if (song === undefined) {
      song = getSong(ethereumInfo.songPlaying)
    }
    song.fade(song.volume(), 0, 1000);
    musicTimeouts.push(setTimeout(() => {
      if (song.volume === 0) {
        song.pause()
      }
    }, 1050))
  };

  const fadeInMusic = (song) => {    
    const maxVolume = ethereumInfo.volumeSettings.music
    if (song === undefined) {
      song = getSong()
    }
    if (!song.playing()) {
      song.play()
    }
    song.fade(song.volume(), maxVolume, 1000)
    const songName = getSongName()
    setEthereumInfo((prevState) => { return { ...prevState, songPlaying: songName } })
  };

  const triggerMusic = (muted) => {
    const song = getSong();
    if (!muted) {
      if (song !== undefined && song !== null) fadeInMusic(song)
      setEthereumInfo((prevState) => { return { ...prevState, musicMuted: false } })
    } else {
      if (song !== undefined && song !== null) fadeOutMusic(song)
      setEthereumInfo((prevState) => { return { ...prevState, musicMuted: true } })
    }
    if (song === undefined || song === null) {
      setEthereumInfo((prevState) => { return { ...prevState, songPlaying: undefined } })
    }
  }

  const toggleMusic = () => {
    const muted = d3.select("#music-button--mute").style("display") === "block";
    d3.select("#music-button--mute").style("display", muted ? "none" : "block");
    d3.select("#music-button--volume").style("display", muted ? "block" : "none");
    triggerMusic(!muted);
  };

  useEffect(() => {
    clearMusictimeouts()
    const newBattleSong = getRandomInt(1, 3);
    setRandomizedSongs({
      rankedBattle: newBattleSong,
    });
  }, [location.pathname]);

  useEffect(() => {
    if (ethereumInfo.volumeSettings !== undefined) {
      const songName = getSongName()
      if (ethereumInfo.songPlaying !== songName) {
        if (ethereumInfo.songPlaying !== undefined) {
          fadeOutMusic();
        }
        triggerMusic(
          d3.select("#music-button--mute").style("display") === "block"
        );
      }
    }
  }, [ethereumInfo.volumeSettings, location.pathname]);

  return (
    <>
      {ethereumInfo.volumeSettings !== undefined && (
        <>
          <img
            id="music-button--mute"
            style={{ display: startMusicOn ? "none" : "block" }}
            src={darkBool ? muteIconDark : muteIcon}
            onClick={() => toggleMusic()}
            alt=""
          />
          <img
            id="music-button--volume"
            style={{ display: startMusicOn ? "block" : "none" }}
            src={darkBool ? volumeIconDark : volumeIcon}
            onClick={() => toggleMusic()}
            alt=""
          />
        </>
      )}
    </>
  );
};

export default Music;
