import {
    sizeMultiples,
    defaultLimbPositions,
    getLimbData,
    getPositionAndSize,
} from '../../asset-handling/render-helper'
import {
    getRotation,
    attributeTransition,
    attributeRotation,
    limbTransition,
    getCurrentRotation,
} from '../animation-helper'
import * as d3 from 'd3'

const charSize = sizeMultiples.battle

const leftArmData = getLimbData(charSize, ...defaultLimbPositions.leftArm)
const rightArmData = getLimbData(charSize, ...defaultLimbPositions.rightArm)
const leftLegData = getLimbData(charSize, ...defaultLimbPositions.leftLeg)
const rightLegData = getLimbData(charSize, ...defaultLimbPositions.rightLeg)

function snapBackToGuard(currentId) {
    const characterCard = d3.select(currentId)
    const characterIdNumber = currentId.replace("#character", "")

    // renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Run")

    const head = characterCard.select(`#head-${characterIdNumber}`)
        , body = characterCard.select(`#body-${characterIdNumber}`)
        , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
        , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
        , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
        , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

    const startHeadRotation = getCurrentRotation(head)
    const startBodyRotation = getCurrentRotation(body)
    const startLeftHandRotation = getCurrentRotation(leftHand)
    const startRightHandRotation = getCurrentRotation(rightHand)
    const startLeftFootRotation = getCurrentRotation(leftFoot)
    const startRightFootRotation = getCurrentRotation(rightFoot)

    const headBBox = head.node().getBBox()
        , headX = headBBox.x + headBBox.width / 2
        , headY = headBBox.y + headBBox.height / 2
        , headRotation = getRotation(startHeadRotation, 0, headX, headY)

    const bodyBBox = body.node().getBBox()
        , bodyX = bodyBBox.x + bodyBBox.width / 2
        , bodyY = bodyBBox.y + bodyBBox.height / 2
        , bodyRotation = getRotation(startBodyRotation, 0, bodyX, bodyY)

    const leftFootBBox = leftFoot.node().getBBox()
        , leftFootX = leftFootBBox.x + leftFootBBox.width / 2
        , leftFootY = leftFootBBox.y + leftFootBBox.height / 2
        , leftFootRotation = getRotation(startLeftFootRotation, 0, leftFootX, leftFootY)

    const rightFootBBox = rightFoot.node().getBBox()
        , rightFootX = rightFootBBox.x + rightFootBBox.width / 2
        , rightFootY = rightFootBBox.y + rightFootBBox.height / 2
        , rightFootRotation = getRotation(startRightFootRotation, 0, rightFootX, rightFootY)

    const leftHandBBox = leftHand.node().getBBox()
        , leftHandX = leftHandBBox.x + leftHandBBox.width / 2
        , leftHandY = leftHandBBox.y + leftHandBBox.height / 2
        , leftHandRotation = getRotation(startLeftHandRotation, 0, leftHandX, leftHandY)

    const rightHandBBox = rightHand.node().getBBox()
        , rightHandX = rightHandBBox.x + rightHandBBox.width / 2
        , rightHandY = rightHandBBox.y + rightHandBBox.height / 2
        , rightHandRotation = getRotation(startRightHandRotation, 0, rightHandX, rightHandY)

    // Attribute Transitions
    const initialHeadPosition = getPositionAndSize("head")
    attributeTransition(head, 0, initialHeadPosition[0], initialHeadPosition[1])

    const initialBodyPosition = getPositionAndSize("body")
    attributeTransition(body, 0, initialBodyPosition[0], initialBodyPosition[1])

    const initialLeftHandPosition = getPositionAndSize("leftHand")
    attributeTransition(leftHand, 0, initialLeftHandPosition[0], initialLeftHandPosition[1])

    const initialRightHandPosition = getPositionAndSize("rightHand")
    attributeTransition(rightHand, 0, initialRightHandPosition[0], initialRightHandPosition[1])

    const initialLeftFootPosition = getPositionAndSize("leftFoot")
    attributeTransition(leftFoot, 0, initialLeftFootPosition[0], initialLeftFootPosition[1])

    const initialRightFootPosition = getPositionAndSize("rightFoot")
    attributeTransition(rightFoot, 0, initialRightFootPosition[0], initialRightFootPosition[1])

    // Attribute Rotations
    attributeRotation(head, 0, headRotation)
    attributeRotation(body, 0, bodyRotation)
    attributeRotation(leftFoot, 0, leftFootRotation)
    attributeRotation(rightFoot, 0, rightFootRotation)
    attributeRotation(leftHand, 0, leftHandRotation)
    attributeRotation(rightHand, 0, rightHandRotation)

    // Limb Transition
    const leftArm = characterCard.select("#leftArm")
    limbTransition(leftArm, 0, leftArmData)

    const rightArm = characterCard.select("#rightArm")
    limbTransition(rightArm, 0, rightArmData)

    const leftLeg = characterCard.select("#leftLeg")
    limbTransition(leftLeg, 0, leftLegData)

    const rightLeg = characterCard.select("#rightLeg")
    limbTransition(rightLeg, 0, rightLegData)
}

function idleAnimation(yourIdNumber) {
    const currentId = `#character${yourIdNumber}`
    d3.select("#run-flag").style("display", "none")
    snapBackToGuard(currentId)
}

export { idleAnimation }