import { getNftImage } from './other-nft-projects'
import * as d3 from 'd3'
import axios from 'axios'
require('dotenv').config()

const backend = process.env.REACT_APP_BACKEND

async function attachComposableMetadata(char, id, ethereumInfo) {
  var composableMetadata
  if (char.dna.includes("composable")) {
    const composableMetadataResult = await axios.get(`${backend}fighters/get-composable-metadata?id=${id}`)
    if (composableMetadataResult.data.length === 0) {
      axios.post(backend + 'fighters/create-composable-fighter', {
        id: id, network: ethereumInfo.networkName
      })
    }
    else {
      const nftCollection = composableMetadataResult.data[0].nft_collection
      if (nftCollection !== null && nftCollection !== "") {
        char.dna = char.dna.replace("--off", "--on")
        const composableImage = await getNftImage(
          composableMetadataResult.data[0].token_id,
          nftCollection,
          ethereumInfo.userAddress,
          ethereumInfo.mainnetWeb3
        )
        char.composableMetadata = {
          ...composableMetadataResult.data[0],
          image: composableImage[0],
          isSvg: composableImage[1],
          pointerEvents: "none"
        }
      }
    }
  }
  return char
}

const addScreenFilling = (element, path) => {
  return element.append("path")
    .attr("id", "helmet-filling")
    .attr("d", path)
    .style("fill", "white")
}

const setComposableImage = (headId, imgData, metadata) => {
  const nftDisplayHead = d3.select(headId)
  nftDisplayHead.selectAll(".screen-regular-image").remove()
  nftDisplayHead.selectAll(".screen-svg-image").remove()
  nftDisplayHead.select("#helmet-filling").remove()
  
  const headAnchor = d3.select(headId).select("g")
  const fillablePathId = "#fillable-path"
  headAnchor.select(fillablePathId)
    .style("fill", "transparent")
    .style("pointer-events", "none")
  const fillablePath = headAnchor.select(fillablePathId).attr("d")
  const screenFilling = addScreenFilling(headAnchor, fillablePath)
  const clipPath = headAnchor.append("clipPath").attr("id", "head-clip")
  clipPath.append("path").attr("d", fillablePath)

  const addDefaultAttributes = (screen) => {
    screen
      .attr("x", metadata !== undefined ? metadata.x * 100 + "%" : "0%")
      .attr("y", metadata !== undefined ? metadata.y * 100 + "%" : "0%")
      .attr('height', metadata !== undefined ? metadata.size * 100 + "%" : "100%")
      .attr('width', metadata !== undefined ? metadata.size * 100 + "%" : "100%")
      .style("cursor", "pointer")
      .style("pointer-events", metadata !== undefined ? metadata.pointerEvents : "auto")
  }
  var screenImage
  if (imgData.svg !== undefined) {
    screenImage = headAnchor.append("svg")
      .attr("class", "screen-svg-image")
      .attr("id", "screen-update-image")
      .style("clip-path", "url(#head-clip)")
      .html(imgData.svg)
      .lower()
  }
  else {
    screenImage = headAnchor.append("svg:image")
      .attr("class", "screen-regular-image")
      .attr("id", "screen-update-image")
      .style("clip-path", "url(#head-clip)")
      .attr("xlink:href", decodeURI(imgData.link))
      .lower()
  }
  screenFilling.lower()
  if (imgData.svg !== undefined) {
    addDefaultAttributes(screenImage.select("svg"))
  }
  else {
    addDefaultAttributes(screenImage)
  }

  return screenImage
}

export {
  attachComposableMetadata,
  setComposableImage
}
