import React, { useContext } from 'react'
import { Context } from "../../../Store"
import "../../../css/website-scaffolding/network-identifier.css"

const NetworkIdentifier = () => {
  const [ethereumInfo, setEthereumInfo] = useContext(Context)

  const networkIdToData = {
    "1": {name: "Main Network", color: "rgb(89,180,174)"},
    "3": {name: "Ropsten Test Network", color: "rgb(236,90,141)"},
    "4": {name: "Rinkeby Test Network", color: "rgb(239,196,92)"},
    "42": {name: "Kovan Test Network", color: "rgb(136,108,247)"},
    "421611": {name: "Arbitrum Test Network", color: "rgb(0,0,0)"},
    "5777": {name: "Ganache Test Network", color: "#eee"}
  }

  const networkData = networkIdToData[ethereumInfo.network]
  var networkName, networkColor;
  if (networkIdToData[ethereumInfo.network] !== undefined) {
    networkName = networkIdToData[ethereumInfo.network].name
    networkColor = networkIdToData[ethereumInfo.network].color
  }
  else {
    networkName = "Not Connected"
    networkColor = "white"
  }

  const lightColorStyling = {
    backgroundColor: networkColor,
    boxShadow: `0 0 2px 1px ${networkColor},
                0 0 4px 2px ${networkColor},
                0 0 6px 3px ${networkColor}`
  }

  return (
    <div className="network-identifier__container">
      <div className="network-identifier__light" style={lightColorStyling}></div>
      <p className="network-identifier__text">{networkName}</p>
    </div>
  )
}

export default NetworkIdentifier
