import React, { useEffect } from 'react'
import Character from '../../generic-components/Character'
import { countryAssets } from '../../helpers/asset-files/country-assets'
import platform from '../../../local-assets/token-display/display-platform.png'
import '../../../css/game-play/fighter-selection.css'
import * as d3 from 'd3'

const FighterPodium = ({
   char, containerId, showButtonFunction, buttonText, buttonFunction, buttonInputs, customButtonStyling 
 }) => {
  const squareFlagCountries = [
    "China", 
    "Russia", 
    "Thailand", 
    "USA", 
    "Canada", 
    "Japan", 
    "Nigeria", 
    "England",
    "Ukraine",
    "Vietnam",
    "Philippines",
    "Estonia"
]

  const id = char !== undefined ? char.id : "empty"
  const highlightCharacter = () => {
    if (char !== undefined) {
      if (squareFlagCountries.includes(char.country)) {
        d3.select(`#vertical-glow--${id}`)
          .classed("rest-of-world", false)
          .style("background-image", `url(${countryAssets[`${char.country} (Square)`]})`)        
      }
      else {
        d3.select(`#vertical-glow--${id}`)
          .classed("rest-of-world", true)
          .style("background-image", `url(${
            char.country !== undefined ? countryAssets[char.country] : countryAssets["Core Team"]
          })`)
      }
    }
    else {
      d3.select(`#vertical-glow--${id}`)
        .style("background-image", "none")
    }
  }

  useEffect(() => {
    highlightCharacter()
  }, [char])

  return (
    <>
      <div id={containerId} className="character-visual__container">
        <div id={`vertical-glow--${id}`} className="vertical-glow"></div>
        <div className="vertical-glow--white-middle"></div>
        <div className="circle-glow" id="fighter-shadow"></div>
        <img id="fighter-selection__platform" src={platform} alt=""/>
        {
          char !== undefined &&
          <Character
            key={`showcase${char.id}`}
            {
              ...{
                ...char,
                id: "x" + char.id,
                size: 1.1,
                yOffset: "15%",
                xOffset: "52%",
                specialClass: "fighter-glow",
                renderCrown: false
              }
            }>
          </Character>
        }
        {
          showButtonFunction(char) &&
          <div className="button--generic button--fit"
               style={{
                 ...customButtonStyling,
                 display: char ? "block" : "none",
                 pointerEvents: "auto",
               }}
               onClick={() => buttonFunction(...buttonInputs)}>
               {buttonText}
          </div>
        }
      </div>
    </>
  )
}

export default FighterPodium
