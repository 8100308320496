import React from 'react'
import ReactPaginate from 'react-paginate'
import '../../../css/generic-styling/pagination.css'

const TeamLeaderboardTable = ({
  teamAggregateStats, pageCount, changePage, pageNumber, charactersPerPage
}) => {
  var sortableTeamStats = [];
  for (var team in teamAggregateStats) {
    sortableTeamStats.push([team, teamAggregateStats[team]])
  }
  sortableTeamStats.sort(function(a, b) {
      return b[1].score/b[1].numFighters - a[1].score/a[1].numFighters
  })

  return (
    <>
      <div className="leaderboard__container">
        <div className="leaderboard">
          <div className="leaderboard__header">
            <h3>TEAM</h3>
            <h3>GAMES PLAYED</h3>
            <h3>WIN RATE</h3>
            <h3>RATING</h3>            
          </div>
          <div className="leaderboard__body">
            {
              sortableTeamStats.slice(
                pageNumber.page * charactersPerPage, (pageNumber.page + 1) * charactersPerPage
              ).map((teamStat, k) => {
                const teamName = teamStat[0]
                const row = teamStat[1]
                const winRate = row.wins / row.gamesPlayed
                const winRateString = isNaN(winRate) ? "-" : parseInt(winRate * 1000) / 10 + "%"
                var additionalRowClass = ""
                if (k === 0) additionalRowClass = "first-row"
                else if (k === charactersPerPage - 1) additionalRowClass = "last-row"
                return (
                  <div key={`row-id-${teamName}`} id={`row-${teamName}`}
                      className={`leaderboard__row row-${k % 3} ${additionalRowClass}`}>
                    <p className="team-name">{teamName}</p>
                    <p className="games-played">{row.gamesPlayed}</p>
                    <p className="win-rate">{winRateString}</p>
                    <p className="score">{Math.round(row.score / row.numFighters)}</p>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div style={{bottom: "0"}}>
          <div className="pagination__container">
            <ReactPaginate
              previousLabel=""
              nextLabel=""
              pageCount={pageCount}
              onPageChange={changePage}
              forcePage={pageNumber.page}
              containerClassName={"pagination__buttons"}
              previousLinkClassName={"pagination__previous-button"}
              nextLinkClassName={"pagination__next-button"}
              disabledClassName={"pagination__buttons--disabled"}
              activeClassName={"pagination__buttons--active"}
            >
            </ReactPaginate>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamLeaderboardTable
