import React, { useEffect, useRef } from 'react'
import { characterRender } from '../helpers/asset-handling/render'
import {
  defaultCharacterWidth,
  defaultCharacterHeight
} from '../helpers/asset-handling/render-helper'
import '../../css/character-display/character.css'

const widthHeightRatio = 1.75

const Character = ({
  id, dna, mirror, yOffset, xOffset, size, specialClass, renderCrown,
  inspectorBool, setFighterState, attachShinyGradient, composableMetadata
}) => {
  const refContainer = useRef()
  const width = defaultCharacterWidth * size
  const height = defaultCharacterHeight * size

  useEffect(() => {
    const currentId = "#" + refContainer.current.id
    const weapon = dna === "dylan" ? "pencil" : undefined
    attachShinyGradient = attachShinyGradient !== undefined ? attachShinyGradient : true
    characterRender(
      currentId,
      dna,
      mirror,
      yOffset,
      xOffset,
      size,
      specialClass,
      renderCrown,
      inspectorBool,
      setFighterState,
      weapon,
      attachShinyGradient,
      composableMetadata
    )
  }, [id])

  var sizing
  if (specialClass === "drop-shadow") {
    sizing = {width: "8%", height: `${8 * 1.6 * widthHeightRatio}%`}
  }
  else if (specialClass === "fighter-icon") {
    sizing = {width: `${70}%`, height: `${70 * widthHeightRatio}%`}
  }
  else if (specialClass === "fighter-glow" || specialClass === "fighter-training-config") {
    sizing = {width: `${40}%`, height: `${40 * widthHeightRatio}%`}
  }
  else if (specialClass === "fighter-trophy") {
    sizing = {width: `${55}%`, height: `${55 * widthHeightRatio}%`}
  }
  else if (specialClass === "fighter-laboratory") {
    sizing = {width: `${35}%`, height: `${35 * widthHeightRatio}%`}
  }
  else if (specialClass === "fighter-simulation-option") {
    sizing = {width: `10vw`, height: `16.25vw`}
  }
  else if (specialClass === "fighter-simulation-small-option") {
    sizing = {width: `6vw`, height: `9.75vw`}
  }
  else {
    sizing = {width: `${width}px`, height: `${height}px`}
  }

  return (
    <div className="character"
         style={sizing}
         key={id}
         id={`character${id}`}
         ref={refContainer}>
    </div>
  )
}

export default Character