import homeMusic from "../../../audio/music/home.mp3";
import marketMusic from "../../../audio/music/market.mp3";
import collectionMusic from "../../../audio/music/collection.mp3";
import leaderboardMusic from "../../../audio/music/leaderboard.mp3";
import fighterSelectionMusic from "../../../audio/music/fighter-selection.mp3";
import rankedBattle1Music from "../../../audio/music/ranked-battle-1.mp3";
import rankedBattle2Music from "../../../audio/music/ranked-battle-2.mp3";
import simulationMusic from "../../../audio/music/simulation.wav";
import inspectorMusic from "../../../audio/music/inspector.mp3";
import trainingMusic from "../../../audio/music/training.mp3";
import { Howl } from "howler";

const defaultMusicInputs = { loop: true, volume: 0 }

const homeSong = new Howl({ src: [homeMusic], ...defaultMusicInputs });
const marketSong = new Howl({ src: [marketMusic], ...defaultMusicInputs });
const collectionSong = new Howl({ src: [collectionMusic], ...defaultMusicInputs });
const leaderboardSong = new Howl({ src: [leaderboardMusic], ...defaultMusicInputs });
const fighterSelectionSong = new Howl({ src: [fighterSelectionMusic], ...defaultMusicInputs });
const rankedBattle1Song = new Howl({ src: [rankedBattle1Music], ...defaultMusicInputs });
const rankedBattle2Song = new Howl({ src: [rankedBattle2Music], ...defaultMusicInputs });
const simulationSong = new Howl({ src: [simulationMusic], ...defaultMusicInputs });
const inspectorSong = new Howl({ src: [inspectorMusic], ...defaultMusicInputs });
const trainingSong = new Howl({ src: [trainingMusic], ...defaultMusicInputs });

const musicAssets = {
  homeMusic: homeSong,
  marketMusic: marketSong,
  collectionMusic: collectionSong,
  leaderboardMusic: leaderboardSong,
  fighterSelectionMusic: fighterSelectionSong,
  rankedBattle1Music: rankedBattle1Song,
  rankedBattle2Music: rankedBattle2Song,
  simulationMusic: simulationSong,
  inspectorMusic: inspectorSong,
  trainingMusic: trainingSong,
};

export { musicAssets };
