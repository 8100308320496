import {
  sizeMultiples,
  defaultLimbPositions,
  getLimbData,
  getPositionAndSize,
} from '../../asset-handling/render-helper'
import {
  getRotation,
  attributeTransition,
  attributeRotation,
  limbTransition,
  delayBase,
} from '../animation-helper'
import { renderFX } from '../fx-helper'
import * as d3 from 'd3'

// Some parameters for the size and position of body parts for the default fighter
const charSize = sizeMultiples.battle

const leftArmData = getLimbData(charSize, ...defaultLimbPositions.leftArm)
const rightArmData = getLimbData(charSize, ...defaultLimbPositions.rightArm)
const leftLegData = getLimbData(charSize, ...defaultLimbPositions.leftLeg)
const rightLegData = getLimbData(charSize, ...defaultLimbPositions.rightLeg)

function lowKickAnimation(id) {
  const currentId = `#character${id}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  characterCard.raise() // Makes sure that the kicking character is on top

  const head = characterCard.select(`#head-${characterIdNumber}`)
    , body = characterCard.select(`#body-${characterIdNumber}`)
    , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
    , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
    , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
    , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`);

  const headBBox = head.node().getBBox()
    , headX = headBBox.x + headBBox.width / 2
    , headY = headBBox.y + headBBox.height / 2
    , headRotation = getRotation(0, -5, headX, headY)
    , headRotation2 = getRotation(-12, -5, headX, headY)
    , headRotation3 = getRotation(-5, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
    , bodyX = bodyBBox.x + bodyBBox.width / 2
    , bodyY = bodyBBox.y + bodyBBox.height / 2
    , bodyRotation = getRotation(0, -5, bodyX, bodyY)
    , bodyRotation2 = getRotation(-20, -5, bodyX, bodyY)
    , bodyRotation3 = getRotation(-5, 0, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
    , leftHandX = leftHandBBox.x + leftHandBBox.width / 2
    , leftHandY = leftHandBBox.y + leftHandBBox.height / 2
    , leftHandRotation = getRotation(0, 11, leftHandX, leftHandY)
    , leftHandRotation2 = getRotation(140, 65, leftHandX, leftHandY)
    , leftHandRotation4 = getRotation(65, -10, leftHandX, leftHandY)
    , leftHandRotation5 = getRotation(-10, 0, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
    , rightHandX = rightHandBBox.x + rightHandBBox.width / 2
    , rightHandY = rightHandBBox.y + rightHandBBox.height / 2
    , rightHandRotation = getRotation(0, -25, rightHandX, rightHandY)
    , rightHandRotation2 = getRotation(-30, 20, rightHandX, rightHandY)
    , rightHandRotation3 = getRotation(10, 0, rightHandX, rightHandY)

  const rightFootBBox = rightFoot.node().getBBox()
    , rightFootX = rightFootBBox.x + rightFootBBox.width / 2
    , rightFootY = rightFootBBox.y + rightFootBBox.height / 2
    , rightFootRotation = getRotation(0, 52, rightFootX, rightFootY)
    , rightFootRotation2 = getRotation(52, -20, rightFootX, rightFootY)
    , rightFootRotation3 = getRotation(-20, -5, rightFootX, rightFootY)
    , rightFootRotation4 = getRotation(-5, 0, rightFootX, rightFootY)

  renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Low Kick")

  // Attribute Transitions
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, 0, initialHeadPosition[0] - 0.07, initialHeadPosition[1])
  attributeTransition(head, delayBase, initialHeadPosition[0] - 0.3, initialHeadPosition[1] + 0.13)
  attributeTransition(head, delayBase * 2, initialHeadPosition[0] - 0.21, initialHeadPosition[1] + 0.2)
  attributeTransition(head, delayBase * 3, initialHeadPosition[0] - 0.03, initialHeadPosition[1] + 0.04)
  attributeTransition(head, delayBase * 4, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, initialBodyPosition[0] - 0.03, initialBodyPosition[1] - 0.02)
  attributeTransition(body, delayBase, initialBodyPosition[0] - 0.17, initialBodyPosition[1] + 0.1)
  attributeTransition(body, delayBase * 2, initialBodyPosition[0] + 0.02, initialBodyPosition[1] + 0.17)
  attributeTransition(body, delayBase * 3, initialBodyPosition[0], initialBodyPosition[1] + 0.02)
  attributeTransition(body, delayBase * 4, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, initialLeftHandPosition[0] - 0.02, initialLeftHandPosition[1] + 0.06)
  attributeTransition(leftHand, delayBase, initialLeftHandPosition[0] - 0.38, initialLeftHandPosition[1] + 0.27)
  attributeTransition(leftHand, delayBase * 2, initialLeftHandPosition[0] - 0.165, initialLeftHandPosition[1] + 0.25)
  attributeTransition(leftHand, delayBase * 3, initialLeftHandPosition[0] + 0.005, initialLeftHandPosition[1] + 0.04)
  attributeTransition(leftHand, delayBase * 4, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, initialRightHandPosition[0] - 0.14, initialRightHandPosition[1] - 0.095)
  attributeTransition(rightHand, delayBase, initialRightHandPosition[0] - 0.2, initialRightHandPosition[1] + 0.12)
  attributeTransition(rightHand, delayBase * 2, initialRightHandPosition[0] - 0.14, initialRightHandPosition[1] + 0.2)
  attributeTransition(rightHand, delayBase * 3, initialRightHandPosition[0] - 0.01, initialRightHandPosition[1] + 0.03)
  attributeTransition(rightHand, delayBase * 4, initialRightHandPosition[0], initialRightHandPosition[1])

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, delayBase, initialLeftFootPosition[0] + 0.02, initialLeftFootPosition[1])
  attributeTransition(leftFoot, delayBase * 3, initialLeftFootPosition[0], initialLeftFootPosition[1])

  const initialRightFootPosition = getPositionAndSize("rightFoot")
  attributeTransition(rightFoot, 0, initialRightFootPosition[0] - 0.18, initialRightFootPosition[1] - 0.01)
  attributeTransition(rightFoot, delayBase, initialRightFootPosition[0] - 0.33, initialRightFootPosition[1] - 0.1)
  attributeTransition(rightFoot, delayBase * 2, initialRightFootPosition[0] + 0.37, initialRightFootPosition[1] - 0.05)
  attributeTransition(rightFoot, delayBase * 3, initialRightFootPosition[0], initialRightFootPosition[1] - 0.03)
  attributeTransition(rightFoot, delayBase * 4, initialRightFootPosition[0], initialRightFootPosition[1])

  // Attribute Rotations
  attributeRotation(head, 0, headRotation)
  attributeRotation(head, delayBase, headRotation2)
  attributeRotation(head, delayBase * 4, headRotation3)

  attributeRotation(body, 0, bodyRotation)
  attributeRotation(body, delayBase, bodyRotation2)
  attributeRotation(body, delayBase * 4, bodyRotation3)

  attributeRotation(leftHand, 0, leftHandRotation)
  attributeRotation(leftHand, delayBase, leftHandRotation2)
  attributeRotation(leftHand, delayBase * 2, leftHandRotation4)
  attributeRotation(leftHand, delayBase * 4, leftHandRotation5)

  attributeRotation(rightHand, 0, rightHandRotation)
  attributeRotation(rightHand, delayBase, rightHandRotation2)
  attributeRotation(rightHand, delayBase * 4, rightHandRotation3)

  attributeRotation(rightFoot, delayBase, rightFootRotation)
  attributeRotation(rightFoot, delayBase * 2, rightFootRotation2)
  attributeRotation(rightFoot, delayBase * 3, rightFootRotation3)
  attributeRotation(rightFoot, delayBase * 4, rightFootRotation4)

  const newLeftArmData = getLimbData(charSize, 0.34, 0.48, -0.04, 0.6, 0.3, 0.6)
  const newRightArmData = getLimbData(charSize, 0.5, 0.5, 0.57, 0.68, 0.7, 0.55)
  const newLeftLegData = getLimbData(charSize, 0.33, 0.7, 0.3, 0.85, 0.11, 0.918)
  const newRightLegData = getLimbData(charSize, 0.5, 0.71, 0.61, 0.8, 0.43, 0.91)

  const newLeftArmData2 = getLimbData(charSize, 0.18, 0.58, -0.15, 0.58, -0.07, 0.7)
  const newRightArmData2 = getLimbData(charSize, 0.35, 0.6, 0.45, 0.685, 0.59, 0.675)
  const newLeftLegData2 = getLimbData(charSize, 0.24, 0.81, -0.02, 0.83, 0.26, 0.905)
  const newRightLegData2 = getLimbData(charSize, 0.4, 0.76, 0.71, 0.8, 0.38, 0.82)

  const newLeftArmData3 = getLimbData(charSize, 0.35, 0.63, -0.15, 0.77, 0.14, 0.75)
  const newRightArmData3 = getLimbData(charSize, 0.5, 0.63, 0.62, 0.63, 0.67, 0.65)
  const newLeftLegData3 = getLimbData(charSize, 0.35, 0.84, 0.06, 0.81, 0.28, 0.905)
  const newRightLegData3 = getLimbData(charSize, 0.5, 0.83, 0.75, 0.85, 0.98, 0.88)

  const newLeftArmData4 = getLimbData(charSize, 0.32, 0.52, -0.025, 0.64, 0.3, 0.6)
  const newRightArmData4 = getLimbData(charSize, 0.52, 0.56, 0.61, 0.66, 0.77, 0.62)
  const newLeftLegData4 = getLimbData(charSize, 0.365, 0.73, 0.32, 0.87, 0.13, 0.91)
  const newRightLegData4 = getLimbData(charSize, 0.5, 0.735, 0.7, 0.77, 0.59, 0.92)

  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, delayBase, newLeftArmData2)
  limbTransition(leftArm, delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, delayBase * 3, newLeftArmData4)
  limbTransition(leftArm, delayBase * 4, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, delayBase, newRightArmData2)
  limbTransition(rightArm, delayBase * 2, newRightArmData3)
  limbTransition(rightArm, delayBase * 3, newRightArmData4)
  limbTransition(rightArm, delayBase * 4, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayBase * 2, newLeftLegData3)
  limbTransition(leftLeg, delayBase * 3, newLeftLegData4)
  limbTransition(leftLeg, delayBase * 4, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, 0, newRightLegData)
  limbTransition(rightLeg, delayBase, newRightLegData2)
  limbTransition(rightLeg, delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, delayBase * 3, newRightLegData4)
  limbTransition(rightLeg, delayBase * 4, rightLegData)
}

export {
  lowKickAnimation
}