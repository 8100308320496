import React, { useState } from 'react'

const initialState = {
  web3: undefined,
  network: undefined,
  smartContract: undefined,
  userAddress: undefined,
  userFighters: [],
}

export const Context = React.createContext()

const Store = ({ children }) => {
  const [ethereumInfo, setEthereumInfo] = useState(initialState)

  return (
    <Context.Provider value={[ethereumInfo, setEthereumInfo]}>
      {children}
    </Context.Provider>
  )
}

export default Store
