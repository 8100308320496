import React from 'react'
import ReactPaginate from 'react-paginate'
import Character from '../../generic-components/Character'
import { countryAssets } from '../../helpers/asset-files/country-assets'
import '../../../css/generic-styling/pagination.css'

const LeaderboardTable = ({
  leaderboardData, selectFighter, pageCount, changePage, pageNumber, charactersPerPage
}) => {
  const leaderboardCharInputs = {
    xOffset: "50%",
    yOffset: "8%",
    mirror: false,
    size: 0.2,
    specialClass: "fighter-icon",
  }  

  return (
    <>
      <div className="leaderboard__container">
        <div className="leaderboard">
          <div className="leaderboard__header">
            <h3>FIGHTER</h3>
            <h3>ID</h3>
            <h3>GAMES PLAYED</h3>
            <h3>WIN RATE</h3>
            <h3>SCORE</h3>
            <h3 style={{marginRight: "1%"}}>TEAM</h3>
          </div>
          <div className="leaderboard__body">
            {leaderboardData.map((row, k) => {
              const characterInputs = {
                ...leaderboardCharInputs,
                id: "visual-" + row.character.id,
                dna: row.character.dna,
                limbColor: row.character.limbColor,
                skinColor: row.character.skinColor,
                composableMetadata: row.character.composableMetadata
              }
              const winRateString = isNaN(row.winRate) ? "" : "%"
              var additionalRowClass = ""
              if (k === 0) additionalRowClass = "first-row"
              else if (k === charactersPerPage - 1) additionalRowClass = "last-row"
              return (
                <div key={`row-id-${row.id}`} id={`row-${row.id}`}
                    className={`leaderboard__row row-${k % 3} ${additionalRowClass}`}
                    onClick={() => selectFighter(row.id)}>
                  <div className="rank-visual">
                    <p >{row.rank}</p>
                    <div className="visual">
                      <Character {...characterInputs}></Character>
                    </div>
                  </div>
                  <p className="id">#{row.id}</p>
                  <p className="games-played">{row.gamesPlayed}</p>
                  <p className="win-rate">{parseInt(row.winRate * 1000) / 10}{winRateString}</p>
                  <p className="score">{row.score}</p>
                  <div className="country-image">
                    <img src={countryAssets[row.country]} alt="" />
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div style={{bottom: "0"}}>
          <div className="pagination__container">
            <ReactPaginate
              previousLabel=""
              nextLabel=""
              pageCount={pageCount}
              onPageChange={changePage}
              forcePage={pageNumber.page}
              containerClassName={"pagination__buttons"}
              previousLinkClassName={"pagination__previous-button"}
              nextLinkClassName={"pagination__next-button"}
              disabledClassName={"pagination__buttons--disabled"}
              activeClassName={"pagination__buttons--active"}
            >
            </ReactPaginate>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeaderboardTable
